import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { AppIconColor } from '../../styles/IconStyle'
import usePath from '../../hooks/usePath'
import DisplayElement from '../display/DisplayElement'
import * as BsIcons from 'react-icons/bs'
import { TableDecorator } from './Table'

const TableList = styled.ol`
    padding: 0px;
    margin: 0px;
    list-style: none;
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
    background-color: ${({ theme }) => theme.table.background};
    @media (min-width: ${({ theme }) => theme.breakpoint.xs}) {
        display: none;
    }
`

const TableListItem = styled.li`
    min-width: 0px;
    opacity: 1;
    min-height: 56px;
    position: relative;
    border-top: 1px solid ${({ theme }) => theme.table.borderTop};
    -webkit-box-align: center;
    align-items: center;
    color: ${({ theme }) => theme.font.color}; //Doesn't apply
    cursor: pointer;
    padding: 0.5rem;
`

const CardWrapper = styled.div`
    display: grid;
    grid-template-columns: 2fr auto;
    p {
        margin:0;
        display: block;
        color: ${({ theme }) => theme.font.color};
        user-select: auto;
        transition-property: color;
        transition-duration: 0.1s;
        transition-timing-function: ease-out;
    }
    svg {
        margin-right: 0.5rem;
    }
`

const TableLink = styled(Link)`
    text-decoration: none;
`

const Tags = styled.div`
    display: inline;
`

const Flex = styled.div`
    display: flex;
    align-items: center;
`
const LeftTag = styled.p`
    font-size: var(--font-S);
    font-weight: 800;
`

const LeftSubTag = styled.p`
    font-size: var(--font-XS);
    font-weight: 500;
`

const RightTag = styled.p`
    font-size: var(--font-S);
    font-weight: 800;
    text-align: right;
`
const RightSubTag = styled.p`
    font-size: var(--font-XS);
    font-weight: 500;
    text-align: right;
`

const IconWrapper = styled.div`
    padding: ${props => props.$padding}; 
`

const NoData = (
    <TableList>
        <TableListItem>
            <CardWrapper>
                <Flex>
                    <IconWrapper>
                        <BsIcons.BsExclamationOctagonFill />
                    </IconWrapper>
                    <p>No data available.</p>
                </Flex>
            </CardWrapper>
        </TableListItem>
    </TableList>
)


const TableRows_m = ({ result, mobile, slug, tableKey = 'id', path = '' }) => {

    // format the path by merging the endpoint to the current url
    const { url } = usePath(path)

    let slugPath = ''
    if (slug !== '') slugPath = `/?state=${slug}`

    if (!result.data || result.data.pages.length === 0 || !Array.isArray(result.data.pages[0]?.data)) {
        // Handle the case when result data is empty
        return (
            <>
                {NoData}
            </>

        )
    }

    return (
        <TableList>
            <AppIconColor>
                {result.data.pages.map((page, pageIndex) => (
                    <div key={pageIndex}>
                        {
                            Array.isArray(page.data) && page.data.map(
                                (item, i) => {
                                    return (
                                        <TableListItem key={i}>
                                            <TableLink to={`${url}${item[tableKey]}${slugPath}`}>
                                                <CardWrapper>
                                                    <div>
                                                        <Flex>
                                                            <IconWrapper $padding={mobile.leftIconPadding || '0'}>
                                                                {mobile.leftIcon ? mobile.leftIcon : <></>}
                                                            </IconWrapper>
                                                            <Tags>
                                                                <LeftTag><DisplayElement
                                                                    string={item[mobile.leftTag.field]}
                                                                    type={mobile.leftTag.type}
                                                                    prefix={mobile.leftTag.prefix}
                                                                />
                                                                    {mobile.leftTag.decorator ? <TableDecorator>{item[mobile.leftTag.decorator]}</TableDecorator> : <></>}
                                                                </LeftTag>
                                                                <LeftSubTag><DisplayElement
                                                                    string={item[mobile.leftSubTag.field]}
                                                                    type={mobile.leftSubTag.type}
                                                                    prefix={mobile.leftSubTag.prefix}
                                                                />
                                                                    {mobile.leftSubTag.decorator ? <TableDecorator>{item[mobile.leftSubTag.decorator]}</TableDecorator> : <></>}
                                                                </LeftSubTag>
                                                            </Tags>
                                                        </Flex>
                                                    </div>
                                                    <Flex>
                                                        <Flex>
                                                            <div>
                                                                {mobile.rightTag ? (
                                                                    <RightTag><DisplayElement
                                                                        string={item[mobile.rightTag.field]}
                                                                        type={mobile.rightTag.type} 
                                                                        prefix={mobile.rightTag.prefix}
                                                                        />
                                                                        {mobile.rightTag.decorator ? <TableDecorator>{item[mobile.rightTag.decorator]}</TableDecorator> : <></>}
                                                                    </RightTag>) : <></>}
                                                                {mobile.rightSubTag ? (
                                                                    <RightSubTag><DisplayElement
                                                                        string={item[mobile.rightSubTag.field]}
                                                                        type={mobile.rightSubTag.type}
                                                                        prefix={mobile.rightSubTag.prefix}
                                                                        
                                                                    />
                                                                        {mobile.rightSubTag.decorator ? <TableDecorator>{item[mobile.rightSubTag.decorator]}</TableDecorator> : <></>}
                                                                    </RightSubTag>) : <></>}
                                                            </div>
                                                            {mobile.rightIcon ? mobile.rightIcon : <></>}
                                                        </Flex>
                                                    </Flex>
                                                </CardWrapper>
                                            </TableLink>
                                        </TableListItem>
                                    )
                                }
                            )
                        }
                    </div>)
                )}
            </AppIconColor>
        </TableList>
    )
}

export default TableRows_m