import styled from "styled-components"
import useTheme from "../../../hooks/useTheme"

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.card.background};
    border-radius: 1rem;
    padding: 1rem;
`

const ApplicationArea = styled.div`
  border-radius: 1rem;
  width: 100%;
  height: 100%;
  display: flex;
`

const NavigationArea = styled.div`
    background-color: ${({ theme }) => theme.nav.background};
    border-radius: 1rem 0rem 0rem 1rem;
    padding: 1rem;
    flex: 1;
`

const NavHeader = styled.div`
    color: ${({ theme }) => theme.nav.font};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem;
    margin-bottom: 1rem;
    font-size: var(--font-XS);
`

const NavItem = styled.div`
    color: ${({ theme }) => theme.nav.font};
    display: flex;
    align-items: center;
    padding: 0 0.25rem;
    font-size: var(--font-XS);
    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => theme.nav.active};
    }
`

const ContentArea = styled.div`
    background-color: ${({ theme }) => theme.area.bg};;
    border-radius: 0rem 1rem 1rem 0rem;
    padding: 1rem;
    flex: 3;
`

const TopArea = styled.div`
    background-color: ${({ theme }) => theme.area.bg};;
    display: flex;
    justify-content: space-between;
`

const CompanySelector = styled.p`
    margin: 0;
    font-size: var(--font-XS);
`

const BottomArea = styled.div`
    background-color: ${({ theme }) => theme.area.bg};;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    margin-top: 1rem;
`

const FormExample = styled.div`
    background-color: ${({ theme }) => theme.form.background};
    border-radius: 1rem;
    padding: 1rem;
    width: 100%;
    height: 8rem;
    @media (max-width: 768px) {
        display: none;
    }
`

const GraphExample = styled.div`
    background-color: ${({ theme }) => theme.card.background};
    border-radius: 1rem;
    padding: 1rem;
    width: 100%;
    display: flex;
    align-items: flex-end;
    gap: 1rem;
    height: 8rem;
`

const Bar = styled.div`
    background-color: ${({ theme }) => theme.chart.fillColor};
    border-radius: 0.25rem;
    padding: 0.25rem;
    width: 1rem;
    height: 100%;
    `

const SecBar = styled.div`
    background-color: ${({ theme }) => theme.secondary.main};
    border-radius: 0.25rem;
    padding: 0.25rem;
    width: 1rem;
    height: 75%;
    `

const AppTheme = () => {
    return (
        <>
            <Wrapper>
                <ApplicationArea>
                    <NavigationArea>
                        <NavHeader>App</NavHeader>
                        <NavItem>Link</NavItem>
                        <NavItem>Link</NavItem>
                        <NavItem>Link</NavItem>
                    </NavigationArea>
                    <ContentArea>
                        <TopArea>
                            <div></div>
                            <CompanySelector>Company EN</CompanySelector>
                        </TopArea>
                        <BottomArea>
                            <FormExample>
                                <h4>Form </h4>
                            </FormExample>
                            <GraphExample>
                                <Bar />
                                <SecBar />
                                <Bar />
                            </GraphExample>
                        </BottomArea>
                    </ContentArea>
                </ApplicationArea>
            </Wrapper>
        </>
    )
}

export default AppTheme