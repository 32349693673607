import { useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { SearchButton } from '../generic/SearchButton'
import { Row, Col } from '../generic/Layout'
import { useState } from 'react'
import Table from '../table/Table'

//For Reading the State
import useProfile from '../../hooks/useProfile'
import { useSnapshot } from 'valtio'

import useApi from "../../hooks/useApi"
import { useInfiniteQuery } from "react-query"
import Loader from '../generic/Loader'
import { Button } from '../generic/Core'
import removeElementsByKey from '../../utils/removeElementsbyKey'

const LoadMore = styled(Button)`
  margin-top: 1rem;
  font-size: var(--font-XS);
`

// Custom debounce hook
const useDebounce = (value, delay = 300) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

function replaceComma(inputString) {
  if (!inputString) return '';
  return inputString.replace(/(\d),(?=\d)/g, '$1.');
}

const ViewTable = ({ endpoint, table, config, filter, id }) => {
  const { pState } = useProfile()
  const snapshot = useSnapshot(pState)

  const { getMe } = useApi()

  const [order, setOrder] = useState([table.orderBy, table.orderDirection || 'asc'])
  const [searchTerm, setSearchTerm] = useState('')
  const currentFilter = config.filters.list[filter]

  // Use debounced search term for API calls
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  const [visibleColumns, setVisibleColumns] = useState(table.columns);

  let filterTerm
  let serverFilter
  if (currentFilter?.applyFilter) {
    filterTerm = currentFilter.filters.map(filter => `${filter.id}:${filter.value === '{id}' ? id : filter.value}`).join(';');
    if (currentFilter.serverFilters) {
      serverFilter = currentFilter.serverFilters.map(filter => `${filter.id}:${filter.value}`).join(';');
    } else serverFilter = false
  } else {
    filterTerm = false
    serverFilter = false
  }

  //Handling Table Size
  useEffect(() => {
    setOrder([table.orderBy, table.orderDirection || 'asc'])

    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (table.dropOrder) {
        const reducedColumns = removeElementsByKey(table.columns, table.dropOrder, screenWidth)
        setVisibleColumns(reducedColumns);
      } else {
        setVisibleColumns(table.columns);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [table]);

  const getData = useCallback(async (endpoint, order, searchTerm, filterTerm, pageParam) => {
    //console.log('getData for: ',searchTerm)
    
    const sorting = order
    const term = replaceComma(searchTerm)

    if (term.length >= 3 || filterTerm) {
      let resource = `${endpoint}/?orderBy=${sorting[0]}&direction=${sorting[1]}`
      if (term.length >= 3) resource += `&searchString=${term}`
      if (filterTerm) resource += `&filter=${filterTerm}`
      if (serverFilter) resource += `&serverFilter=${serverFilter}`
      if (pageParam) resource += `&startAfter=${pageParam}`

      const response = await getMe(resource)
      return response
    }

    let resource = `${endpoint}/?orderBy=${sorting[0]}&direction=${sorting[1]}`
    if (pageParam) resource = `&startAfter=${pageParam}`

    const response = await getMe(resource)
    return response
  }, [getMe, serverFilter]);

  const result = useInfiniteQuery(
    [endpoint, order, debouncedSearchTerm, filterTerm],
    ({ pageParam }) => getData(endpoint, order, debouncedSearchTerm, filterTerm, pageParam),
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage, allPages) => {
        if (!lastPage) return null;
        if (lastPage?.meta?.size < snapshot.pagination) return null;
        return lastPage?.meta?.lastId;
      },
    }
  );

  const sortList = useCallback((orderBy) => {
    setOrder(prevOrder => {
      if (prevOrder[0] === orderBy) {
        return [orderBy, prevOrder[1] === 'asc' ? 'desc' : 'asc'];
      }
      return [orderBy, 'asc'];
    });
  }, []);

  if (result.isLoading) {
    return <Loader />
  }

  if (result.isError) {
    return <span>Error: {result.error.message}</span>
  }

  if (result.isSuccess) {
    return (
      <>
        <Table.TitleWrapper name='TableTitleWrapper'>
          <Row>
            <Col name='Col'>
              <SearchButton 
                searchTerm={searchTerm} 
                setSearchTerm={setSearchTerm} 
                searchLabel={table.searchLabel} 
                searchPlaceholder={searchTerm || table.searchPlaceholder}
              />
            </Col>
          </Row>
        </Table.TitleWrapper>
        <Table.Content>
          <Table.Header columns={visibleColumns} order={order} sortList={sortList} />
          {result && <Table.Rows endpoint={endpoint} result={result} columns={visibleColumns} slug={currentFilter?.filterSlug || ''} tableKey={table.tableKey} path={table.path} />}
          {result && <Table.RowsMobile endpoint={endpoint} result={result} mobile={table.mobile} slug={currentFilter?.filterSlug || ''} tableKey={table.tableKey} path={table.path} />}
        </Table.Content>
        {result.hasNextPage && !result.hasMore && (
          <Table.Foot>
            <LoadMore 
              $variant='transparent'
              onClick={() => result.fetchNextPage()}
              disabled={result.isFetchingNextPage}
            >
              {result.isFetchingNextPage ? 'Loading...' : 'Load More'}
            </LoadMore>
          </Table.Foot>
        )}
      </>
    )
  }
}

export default ViewTable