export const widgetDashboardData =
{
    crm: {
        name:'crm-Dashboard',
        metadata: {
            title: 'crm.dashboard.title',
            resetButton: 'all.dashboard.reset.button',
            editButton: 'all.dashboard.edit.button'
        },
        setup: {
            breakpoints: { lg: 1200, md: 768, sm: 480 },
            cols: { lg: 3, md: 2, sm: 1 },
            offset: 24,
            margin: {
                lg: [16, 16],
                md: [16, 16],
                sm: [16, 16],
            },
            rowHeight: 16
        },
        layouts: {
            "lg": [
                { "w": 1, "h": 13, "x": 0, "y": 0, "i": "1", "minW": 1, "minH": 1, "moved": false, "static": false },
                { "w": 1, "h": 7, "x": 1, "y": 0, "i": "2", "minW": 1, "minH": 1, "moved": false, "static": false },
                { "w": 1, "h": 7, "x": 2, "y": 0, "i": "3", "minW": 1, "minH": 1, "moved": false, "static": false },
                { "w": 1, "h": 6, "x": 2, "y": 7, "i": "4", "minW": 1, "minH": 1, "moved": false, "static": false },
                { "w": 1, "h": 6, "x": 1, "y": 7, "i": "5", "minW": 1, "minH": 1, "moved": false, "static": false }],
            "md": [
                { "w": 1, "h": 10, "x": 0, "y": 0, "i": "1", "minW": 1, "minH": 1, "moved": false, "static": false },
                { "w": 1, "h": 6, "x": 1, "y": 0, "i": "2", "minW": 1, "minH": 1, "moved": false, "static": false },
                { "w": 1, "h": 6, "x": 0, "y": 10, "i": "3", "minW": 1, "minH": 1, "moved": false, "static": false },
                { "w": 1, "h": 6, "x": 1, "y": 10, "i": "4", "minW": 1, "minH": 1, "moved": false, "static": false },
                { "w": 1, "h": 4, "x": 1, "y": 6, "i": "5", "minW": 1, "minH": 1, "moved": false, "static": false }],
            "sm": [
                { "w": 1, "h": 7, "x": 0, "y": 0, "i": "1", "minW": 1, "minH": 1, "moved": false, "static": false },
                { "w": 1, "h": 5, "x": 0, "y": 7, "i": "2", "minW": 1, "minH": 1, "moved": false, "static": false },
                { "w": 1, "h": 5, "x": 0, "y": 12, "i": "3", "minW": 1, "minH": 1, "moved": false, "static": false },
                { "w": 1, "h": 5, "x": 0, "y": 17, "i": "4", "minW": 1, "minH": 1, "moved": false, "static": false },
                { "w": 1, "h": 4, "x": 0, "y": 22, "i": "5", "minW": 1, "minH": 1, "moved": false, "static": false }]
        },
        widgets:
            [
                { i: "1", isDraggable: false, isResizable: false, type:"contact.list.widget" },
                { i: "2", isDraggable: false, isResizable: false, type:"crm.create.product.widget" },
                { i: "3", isDraggable: false, isResizable: false, type:"go.home.widget" },
                { i: "4", isDraggable: false, isResizable: false, type:"go.service.widget" },
                { i: "5", isDraggable: false, isResizable: false, type:"crm.text.widget" },
            ]
    },
    sales: {
        name:'sales-Dashboard',
        metadata: {
            title: 'sales.dashboard.title',
            resetButton: 'all.dashboard.reset.button',
            editButton: 'all.dashboard.edit.button'
        },
        setup: {
            breakpoints: { lg: 1200, md: 768, sm: 480 },
            cols: { lg: 3, md: 2, sm: 1 },
            offset: 24,
            margin: {
                lg: [16, 16],
                md: [16, 16],
                sm: [16, 16],
            },
            rowHeight: 16
        },
        layouts: {
            "lg": [
                { "w": 1, "h": 13, "x": 0, "y": 0, "i": "1", "minW": 1, "minH": 1, "moved": false, "static": false },
                { "w": 1, "h": 13, "x": 1, "y":0, "i": "2", "minW": 1, "minH": 1, "moved": false, "static": false }
            ],
            "md": [
                { "w": 1, "h": 10, "x": 0, "y": 0, "i": "1", "minW": 1, "minH": 1, "moved": false, "static": false },
                { "w": 1, "h": 10, "x": 1, "y":0, "i": "2", "minW": 1, "minH": 1, "moved": false, "static": false }
            ],
            "sm": [
                { "w": 1, "h": 7, "x": 0, "y": 0, "i": "1", "minW": 1, "minH": 1, "moved": false, "static": false },
                { "w": 1, "h": 7, "x": 1, "y":0, "i": "2", "minW": 1, "minH": 1, "moved": false, "static": false }

            ]
        },
        widgets:
            [
                { i: "1", isDraggable: false, isResizable: false, type:"contact.list.widget" },
                { i: "2", isDraggable: false, isResizable: false, type:"sales.invoiced.monthly.widget" }
            ]
    },
    inventory: {
        name:'inventory-Dashboard',
        metadata: {
            title: 'inventory.dashboard.title',
            resetButton: 'all.dashboard.reset.button',
            editButton: 'all.dashboard.edit.button'
        },
        setup: {
            breakpoints: { lg: 1200, md: 768, sm: 480 },
            cols: { lg: 3, md: 2, sm: 1 },
            offset: 24,
            margin: {
                lg: [16, 16],
                md: [16, 16],
                sm: [16, 16],
            },
            rowHeight: 16
        },
        layouts: {
            "lg": [
                { "w": 1, "h": 13, "x": 0, "y": 0, "i": "1", "minW": 1, "minH": 1, "moved": false, "static": false },
                { "w": 1, "h": 13, "x": 1, "y":0, "i": "2", "minW": 1, "minH": 1, "moved": false, "static": false }
            ],
            "md": [
                { "w": 1, "h": 10, "x": 0, "y": 0, "i": "1", "minW": 1, "minH": 1, "moved": false, "static": false },
                { "w": 1, "h": 10, "x": 1, "y":0, "i": "2", "minW": 1, "minH": 1, "moved": false, "static": false }
            ],
            "sm": [
                { "w": 1, "h": 7, "x": 0, "y": 0, "i": "1", "minW": 1, "minH": 1, "moved": false, "static": false },
                { "w": 1, "h": 7, "x": 1, "y":0, "i": "2", "minW": 1, "minH": 1, "moved": false, "static": false }

            ]
        },
        widgets:
            [
                { i: "1", isDraggable: false, isResizable: false, type:"product.list.widget" },
                { i: "2", isDraggable: false, isResizable: false, type:"inventory.bar.chart.widget" },
            ]
    },
    purchases: {
        name:'purchases-Dashboard',
        metadata: {
            title: 'purchases.dashboard.title',
            resetButton: 'all.dashboard.reset.button',
            editButton: 'all.dashboard.edit.button'
        },
        setup: {
            breakpoints: { lg: 1200, md: 768, sm: 480 },
            cols: { lg: 3, md: 2, sm: 1 },
            offset: 24,
            margin: {
                lg: [16, 16],
                md: [16, 16],
                sm: [16, 16],
            },
            rowHeight: 16
        },
        layouts: {
            "lg": [
                { "w": 1, "h": 13, "x": 0, "y": 0, "i": "1", "minW": 1, "minH": 1, "moved": false, "static": false },
            ],
            "md": [
                { "w": 1, "h": 10, "x": 0, "y": 0, "i": "1", "minW": 1, "minH": 1, "moved": false, "static": false },
            ],
            "sm": [
                { "w": 1, "h": 7, "x": 0, "y": 0, "i": "1", "minW": 1, "minH": 1, "moved": false, "static": false },
            ]
        },
        widgets:
            [
                { i: "1", isDraggable: false, isResizable: false, type:"contact.list.widget" },
            ]
    },
    service: {
        name:'service-Dashboard',
        metadata: {
            title: 'service.dashboard.title',
            resetButton: 'all.dashboard.reset.button',
            editButton: 'all.dashboard.edit.button'
        },
        setup: {
            breakpoints: { lg: 1200, md: 768, sm: 480 },
            cols: { lg: 3, md: 2, sm: 1 },
            offset: 24,
            margin: {
                lg: [16, 16],
                md: [16, 16],
                sm: [16, 16],
            },
            rowHeight: 90
        },
        layouts: {
            "lg": [
                { "w": 1, "h": 3, "x": 0, "y": 0, "i": "1", "minW": 1, "minH": 1, "moved": false, "static": false },
                { "w": 1, "h": 3, "x": 1, "y": 0, "i": "2", "minW": 1, "minH": 1, "moved": false, "static": false },
                { "w": 1, "h": 6, "x": 2, "y": 0, "i": "3", "minW": 1, "minH": 1, "moved": false, "static": false },
                { "w": 1, "h": 3, "x": 0, "y": 3, "i": "4", "minW": 1, "minH": 1, "moved": false, "static": false },
                { "w": 1, "h": 3, "x": 1, "y": 3, "i": "5", "minW": 1, "minH": 1, "moved": false, "static": false }],
            "md": [
                { "w": 1, "h": 3, "x": 0, "y": 0, "i": "1", "minW": 1, "minH": 1, "moved": false, "static": false },
                { "w": 1, "h": 3, "x": 1, "y": 0, "i": "2", "minW": 1, "minH": 1, "moved": false, "static": false },
                { "w": 2, "h": 1, "x": 0, "y": 3, "i": "3", "minW": 1, "minH": 1, "moved": false, "static": false },
                { "w": 1, "h": 3, "x": 0, "y": 4, "i": "4", "minW": 1, "minH": 1, "moved": false, "static": false },
                { "w": 1, "h": 3, "x": 1, "y": 4, "i": "5", "minW": 1, "minH": 1, "moved": false, "static": false }],
            "sm": [
                { "w": 1, "h": 1, "x": 0, "y": 0, "i": "1", "minW": 1, "minH": 1, "moved": false, "static": false },
                { "w": 1, "h": 2, "x": 0, "y": 1, "i": "2", "minW": 1, "minH": 1, "moved": false, "static": false },
                { "w": 1, "h": 1, "x": 0, "y": 3, "i": "3", "minW": 1, "minH": 1, "moved": false, "static": false },
                { "w": 1, "h": 3, "x": 0, "y": 4, "i": "4", "minW": 1, "minH": 1, "moved": false, "static": false },
                { "w": 1, "h": 3, "x": 0, "y": 7, "i": "5", "minW": 1, "minH": 1, "moved": false, "static": false }]
        },
        widgets:
            [
                { i: "1", isDraggable: false, isResizable: false, type:"customer.list.widget" },
                { i: "2", isDraggable: false, isResizable: false, type:"service.create.product.widget" },
                { i: "3", isDraggable: false, isResizable: false, type:"go.home.widget" },
                { i: "4", isDraggable: false, isResizable: false, type:"go.crm.widget" },
                { i: "5", isDraggable: false, isResizable: false, type:"service.text.widget" },
            ]
    },
    bank: {
        name:'bank-Dashboard',
        metadata: {
            title: 'bank.dashboard.title',
            resetButton: 'all.dashboard.reset.button',
            editButton: 'all.dashboard.edit.button'
        },
        setup: {
            breakpoints: { lg: 1200, md: 768, sm: 480 },
            cols: { lg: 3, md: 2, sm: 1 },
            offset: 24,
            margin: {
                lg: [16, 16],
                md: [16, 16],
                sm: [16, 16],
            },
            rowHeight: 16
        },
        layouts: {
            "lg": [
                { "w": 1, "h": 13, "x": 0, "y": 0, "i": "1", "minW": 1, "minH": 1, "moved": false, "static": false },
            ],
            "md": [
                { "w": 1, "h": 10, "x": 0, "y": 0, "i": "1", "minW": 1, "minH": 1, "moved": false, "static": false },
            ],
            "sm": [
                { "w": 1, "h": 7, "x": 0, "y": 0, "i": "1", "minW": 1, "minH": 1, "moved": false, "static": false },
            ]
        },
        widgets:
            [
                { i: "1", isDraggable: false, isResizable: false, type:"contact.list.widget" },
            ]
    },
}