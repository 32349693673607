import styled from 'styled-components';

//Import the API
import useApi from '../../hooks/useApi';
import { useQuery } from 'react-query';

import useTranslation from '../../hooks/useTranslation';
import useLocale from '../../hooks/useLocale';

import { getNestedValue } from '../../utils/objects/nestedValue';
import extractDataFromPath from '../../../../common/utils/object/extractDataFromPath'

import updateState from '../../utils/objects/updateState';
import sumFields from '../../utils/sumFields';
import { retrieveParams } from '../modal/ModalList';
import runLogic from '../../../../common/logic/runLogic';

//Import SubComponents
import MultiList from './MultiList';
import Warning from '../modal/Warning';
import Loader from '../generic/Loader';

const ModalContainer = styled.div`
`;

const Header = styled.div`
    padding: 0rem 0rem 0.5rem 0rem;
    //border-bottom: 1px solid ${props => props.theme.borderColor};
`;

const Body = styled.div`
    flex: 1;
    overflow-y: auto;
    padding: 0;
`;

const Footer = styled.div`
    padding: 1rem 0rem 1rem 0rem; 
    //border-top: 1px solid ${props => props.theme.borderColor};
`;

const MultiSelect = ({
    entity,
    validated,
    useStore,
    section,
    lineId,
    path
}) => {
    //Setting up utilities
    const { getMe } = useApi();
    const { m } = useTranslation();
    const { lf } = useLocale()

    // Get local state for constraints and parameters
    const [state, setState] = useStore(s => path.length === 0 ? s : getNestedValue(s, path));
    const [fullState, setFullState] = useStore(s => s)
    const prevData = { ...fullState }

    const load = section.meta?.load ? section.meta?.load : true

    const itemId = state?.itemId;
    const constraint = state[section.modal.body.constraint]
    const filter = section.modal.body.filter
    const linesArray = section.modal.onUpdate.linesArray
    const header = section.modal.header;
    const footer = section.modal?.footer || null;
    const body = section.modal?.body || null;
    const params = retrieveParams(header.params, state, lf)
    const col = body?.columns || []
    const footerParams = retrieveParams(footer?.params || [], state, lf)

    const list = state?.[linesArray] || []
    const docId = fullState.id

    // Calculate totals if needed
    const totalAmount = sumFields(list, section.modal?.footer?.sumField || '')

    const fetchData = async () => {
        if (itemId) {
            const endpoint = section.modal.body.endpoint.replace(':id', itemId);
            const response = await getMe(endpoint);
            if (response?.data) {
                return response
            }
        }
    };

    let result = null;

    if (load) {
        result = useQuery([entity, itemId], () => fetchData(), {
            refetchOnMount: true,
        });
    }

    if (section.meta?.calculate) {
        const data = extractDataFromPath(fullState, section.meta.calculate);
        result = data
    }

    const handleSelectionChange = async (items) => {
        // Create empty array for selected lines
        const selectedLines = [];

        // Process items if there are any selections
        if (items && items.length > 0) {
            let remainingConstraint = constraint;  // Use the constraint from state

            // Process items in order of selection
            for (const item of items) {
                if (remainingConstraint <= 0) break;

                const line = {};

                // Map all values specified in valueMap
                if (section.modal.onUpdate?.valueMap) {
                    Object.entries(section.modal.onUpdate.valueMap).forEach(([key, value]) => {
                        // Handle nested paths if value contains dots
                        if (value.includes('.')) {
                            const parts = value.split('.');
                            let currentValue = item;
                            for (const part of parts) {
                                currentValue = currentValue?.[part];
                            }
                            line[key] = currentValue;
                        } else {
                            line[key] = item[value];
                        }
                    });
                }

                // Calculate quantity based on remaining constraint
                const itemQuantityValue = Number(item[section.modal?.footer?.sumField]);
                const allocatedQuantity = Math.min(itemQuantityValue, remainingConstraint);
                remainingConstraint = Math.max(0, remainingConstraint - allocatedQuantity);

                line.quantity = allocatedQuantity;
                line.lineId = `${lineId}.${selectedLines.length}`;
                selectedLines.push(line);

            }
        }

        // Create new state with updated lines array (empty if no selections)
        const newState = {
            ...state,
            [linesArray]: selectedLines // This will be an empty array if no items are selected
        };


        const newFullState = updateState(fullState, path, newState)
        const processedState = await runLogic(newFullState, entity, prevData)

        // Update the state
        setFullState(processedState);
    };

    if (result?.isLoading) { return <Loader /> }
    if (result?.isError) { return <span>Error: {result.data.message}</span> }

    return (
        <ModalContainer>
            {totalAmount !== state['itemQuantity'] && <Header>
                {section?.modal?.header?.description && (
                    <div>
                        {m(header.description, params)}
                    </div>
                )}
            </Header>
            }            <Body>
                {body.type === 'multiList' && <MultiList
                    data={result?.data?.data}
                    onSelectionChange={handleSelectionChange}
                    sumField={section.modal?.footer?.sumField}
                    constraint={constraint}
                    filter={filter}
                    columns={col}
                    existingSelections={list}
                    emptyMessage={body.emptyMessage}
                    lineId={lineId}
                    docId={docId}
                />}
            </Body>
            {footer && <Footer>{m(footer.description, footerParams)}</Footer>}
            {footer?.warning && <Warning warning={footer.warning} state={state} compareValue={totalAmount} />}
        </ModalContainer>
    );
};

export default MultiSelect;
