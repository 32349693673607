import {
    Icon_Invoice, Icon_Arrow_Right, Icon_StockMovement, Icon_StockAdjustment,
    Icon_Shipment, Icon_Receipt
} from '../../styles/icons'

import * as IoIcons from 'react-icons/io'
import * as AiIcons from 'react-icons/ai'
import * as RiIcons from 'react-icons/ri'
import * as BsIcons from 'react-icons/bs'

const listViewData = {
    customerList: {
        title: {
            text: 'customer.list.title',
            createButton: 'customer.list.new',
            newPath: 'new'
        },
        entity: 'customer',
        endpoint: 'customer',
        filters: {
            list: [
                { filterName: 'customer.list.filter.1' },
                { filterName: 'customer.list.filter.2' },
                { filterName: 'customer.list.filter.3' }
            ],
            marginTop: '1rem'
        },
        tables: {
            customer: {
                title: 'customer.list.table.title',
                searchLabel: 'all.list.search',
                searchPlaceholder: 'all.list.search.placeholder',
                orderBy: 'name',
                columns: [{
                    id: 'name',
                    name: 'customer.list.table.name',
                    length: '150px'
                },
                {
                    id: 'email',
                    name: 'customer.list.table.email',
                    length: '250px'
                }
                ],
                mobile: {
                    leftTag: { field: 'name' },
                    leftSubTag: { field: 'email' },
                    rightTag: null,
                    rightSubTag: null,
                    leftBadge: null,
                    rightArrow: null,
                    leftIcon: <IoIcons.IoMdPerson />,
                    rightIcon: <AiIcons.AiOutlineRight />
                }
            }
        }
    },
    contactList: {
        title: {
            text: 'contact.list.title',
            createButton: 'contact.list.new',
            newPath: 'new'
        },
        entity: 'contact',
        endpoint: 'contact',
        filters: {
            list: [
                {
                    filterName: 'contact.list.filter.1',
                    applyFilter: false,
                },
                {
                    filterName: 'contact.list.filter.2',
                    applyFilter: true,
                    filters: [
                        { id: 'companyTypeId', value: 'company' }
                    ]
                },
                {
                    filterName: 'contact.list.filter.3',
                    applyFilter: true,
                    filters: [
                        { id: 'companyTypeId', value: 'person' }
                    ]
                }
            ],
            marginTop: '1rem'
        },
        tables: {
            contact: {
                title: 'contact.list.table.title',
                searchLabel: 'all.list.search',
                searchPlaceholder: 'all.list.search.placeholder',
                orderBy: 'name',
                columns: [{
                    id: 'name',
                    name: 'contact.list.table.name',
                    length: '150px'
                },
                {
                    id: 'email',
                    name: 'contact.list.table.email',
                    length: '250px'
                }
                ],
                mobile: {
                    leftTag: { field: 'name' },
                    leftSubTag: { field: 'email' },
                    rightTag: null,
                    rightSubTag: null,
                    leftBadge: null,
                    rightArrow: null,
                    leftIcon: <IoIcons.IoMdPerson />,
                    rightIcon: <AiIcons.AiOutlineRight />
                }
            }
        }
    },
    interactionList: {
        title: {
            text: 'interaction.list.title',
            createButton: 'interaction.list.new',
            newPath: 'interaction/new'
        },
        entity: 'interaction',
        endpoint: 'contact/:id/interaction',
        orderDirection: 'desc',
        subRoute: true,
        hideNavigation: true,
        filters: {
            list: [
            ],
            marginTop: '1rem'
        },
        tables: {
            interaction: {
                searchLabel: 'all.list.search',
                searchPlaceholder: 'all.list.search.placeholder',
                orderBy: 'date',
                path: 'interaction/',
                columns: [
                    {
                        id: 'date',
                        name: 'interaction.list.table.date',
                        length: '150px',
                        type: 'date'
                    },
                    {
                        id: 'title',
                        type: 'string',
                        name: 'interaction.list.table.title',
                        length: '150px'
                    },
                    {
                        id: 'description',
                        name: 'interaction.list.table.description',
                        length: '450px'
                    }
                ],
                mobile: {
                    leftTag: {
                        field: 'title',
                        type: 'string'
                    },
                    leftSubTag: {
                        field: 'description'
                    },
                    rightTag: {
                        field: 'date',
                        type: 'date'
                    },
                    rightSubTag: null,
                    leftBadge: null,
                    rightArrow: null,
                    leftIcon: <IoIcons.IoMdPerson />,
                    rightIcon: <AiIcons.AiOutlineRight />
                }
            }
        }
    },
    contactPersonList: {
        title: {
            text: 'contactPerson.list.title',
            createButton: 'contactPerson.list.new',
            newPath: 'contactPerson/new'
        },
        entity: 'contactPerson',
        endpoint: 'contact/:id/contactPerson',
        subRoute: true,
        hideNavigation: true,
        filters: {
            list: [
            ],
            marginTop: '1rem'
        },
        tables: {
            contactPerson: {
                searchLabel: 'all.list.search',
                searchPlaceholder: 'all.list.search.placeholder',
                orderBy: 'name',
                path: 'contactPerson/',
                columns: [
                    {
                        id: 'name',
                        name: 'contactPerson.list.table.name',
                        length: '150px',
                        type: 'string'
                    },
                    {
                        id: 'email',
                        name: 'contactPerson.list.table.email',
                        length: '150px',
                        type: 'string'
                    },
                ],
                mobile: {
                    leftTag: {
                        field: 'name',
                        type: 'string'
                    },
                    leftSubTag: {
                        field: 'email'
                    },
                    rightSubTag: null,
                    leftBadge: null,
                    rightArrow: null,
                    leftIcon: <IoIcons.IoMdPerson />,
                    rightIcon: <AiIcons.AiOutlineRight />
                }
            }
        }
    },
    contactInvoiceList: {
        title: {
            text: 'invoice.list.title',
            createButton: 'invoice.list.new',
            entity: 'invoice',
            newPath: 'invoice/new'
        },
        entity: 'invoice',
        endpoint: 'invoice',
        filters: {
            list: [
                {
                    filterName: 'invoice.list.filter.overdue',
                    entity: 'invoice',
                    applyFilter: true,
                    filters: [
                        { id: 'customerId', value: '{id}' },
                    ]
                },
            ],
            marginTop: '1rem'
        },
        tables: {
            invoice: {
                title: 'invoice.list.table.title',
                searchLabel: 'all.list.search',
                searchPlaceholder: 'all.list.search.placeholder',
                orderBy: 'bookedNumber',
                path: '/invoice/',
                tableKey: 'id',
                columns: [{
                    id: 'bookedNumber',
                    name: 'invoice.list.table.number',
                    length: '100px'
                },
                {
                    id: 'date',
                    name: 'invoice.list.table.date',
                    length: '100px',
                    type: 'date'
                },
                {
                    id: 'totalGrossSalesAmount',
                    name: 'invoice.list.table.amount',
                    length: '150px',
                    type: 'amount',
                    decorator: 'currency'
                },
                {
                    id: 'paid',
                    name: 'Payment Status',
                    type: 'boolean',
                    values: ['PAID', 'UNPAID'],
                    length: '90px',
                }
                ],
                dropOrder: {
                    885: [3],
                },
                mobile: {
                    leftTag: { field: 'customerName' },
                    leftSubTag: { field: 'bookedNumber' },
                    rightTag: { field: 'totalGrossSalesAmount', type: 'amount' },
                    rightSubTag: { field: 'date', type: 'date' },
                    leftBadge: null,
                    rightArrow: null,
                    leftIcon: Icon_Invoice,
                    rightIcon: Icon_Arrow_Right
                }
            }
        }
    },
    productList: {
        title: {
            text: 'product.list.title',
            createButton: 'product.list.new',
            newPath: 'new'
        },
        entity: 'product',
        endpoint: 'product',
        filters: {
            list: [
                { filterName: 'product.list.filter.1' },
                { filterName: 'product.list.filter.2' },
                { filterName: 'product.list.filter.3' }
            ],
            marginTop: '1rem'
        },
        tables: {
            product: {
                title: 'product.list.table.title',
                searchLabel: 'all.list.search',
                searchPlaceholder: 'all.list.search.placeholder',
                orderBy: 'name',
                columns: [{
                    id: 'name',
                    name: 'product.list.table.name',
                    length: '150px'
                },
                {
                    id: 'description',
                    name: 'product.list.table.description',
                    length: '350px'
                },
                {
                    id: 'netSalesPrice',
                    name: 'product.list.table.price',
                    length: '100px'
                }
                ],
                dropOrder: {
                    800: [1, 2],
                    1024: [2]
                },
                mobile: {
                    leftTag: { field: 'name' },
                    leftSubTag: { field: 'description' },
                    rightTag: null,
                    rightSubTag: null,
                    leftBadge: null,
                    rightArrow: null,
                    leftIcon: <BsIcons.BsBoxes />,
                    rightIcon: <AiIcons.AiOutlineRight />
                }
            }
        }
    },
    organizationList: {
        title: {
            text: 'organization.list.title',
            createButton: 'organization.list.new',
            newPath: 'new'
        },
        entity: 'organization',
        endpoint: 'organization',
        filters: {
            list: [
            ],
            marginTop: '1rem'
        },
        tables: {
            organization: {
                title: 'organization.list.table.title',
                searchLabel: 'all.list.search',
                searchPlaceholder: 'all.list.search.placeholder',
                orderBy: 'name',
                columns: [
                    {
                        id: 'name',
                        name: 'organization.list.table.name',
                        length: '200px'
                    },
                    {
                        id: 'email',
                        name: 'organization.list.table.email',
                        length: '200px'
                    },
                    {
                        id: 'countryName',
                        name: 'organization.list.table.country',
                        length: '200px'
                    }
                ],
                dropOrder: {
                    700: [1, 2],
                    1024: [1]
                },
                mobile: {
                    leftTag: { field: 'name' },
                    leftSubTag: { field: 'email' },
                    rightTag: null,
                    rightSubTag: null,
                    leftBadge: null,
                    rightArrow: null,
                    leftIcon: <RiIcons.RiBuildingLine />,
                    rightIcon: <AiIcons.AiOutlineRight />,
                    leftIconPadding: '0 0.5rem 0 0'
                }
            }
        }
    },
    userList: {
        title: {
            text: 'user.list.title',
            createButton: 'user.list.new',
            newPath: 'new'
        },
        entity: 'user',
        endpoint: 'user',
        filters: {
            list: [
            ],
            marginTop: '1rem'
        },
        tables: {
            user: {
                title: 'user.list.table.title',
                searchLabel: 'all.list.search',
                searchPlaceholder: 'all.list.search.placeholder',
                orderBy: 'name',
                columns: [
                    {
                        id: 'name',
                        name: 'user.list.table.name',
                        length: '200px'
                    },
                    {
                        id: 'email',
                        name: 'user.list.table.email',
                        length: '200px'
                    },
                    {
                        id: 'role',
                        name: 'user.list.table.role',
                        length: '200px'
                    }
                ],
                mobile: {
                    leftTag: { field: 'name' },
                    leftSubTag: { field: 'email' },
                    rightTag: null,
                    rightSubTag: null,
                    leftBadge: null,
                    rightArrow: null,
                    leftIcon: <IoIcons.IoMdPerson />,
                    rightIcon: <AiIcons.AiOutlineRight />,
                    leftIconPadding: '0 0.5rem 0 0'
                }
            }
        }
    },
    invoiceList: {
        title: {
            text: 'invoice.list.title',
            createButton: 'invoice.list.new',
            entity: 'invoice',
            newPath: 'new'
        },
        entity: 'invoice',
        endpoint: 'invoice',
        filters: {
            list: [
                {
                    filterName: 'invoice.list.filter.1',
                    entity: 'invoice',
                    applyFilter: true,
                    filters: [
                        { id: 'state', value: 'draft' },
                        { id: 'archived', value: 'false' }
                    ],
                    filterSlug: 'draft'
                },
                {
                    filterName: 'invoice.list.filter.2',
                    entity: 'invoice',
                    applyFilter: true,
                    filters: [
                        { id: 'state', value: 'final' },
                        { id: 'archived', value: 'false' }
                    ],
                    filterSlug: 'booked'
                },
                {
                    filterName: 'invoice.list.filter.overdue',
                    entity: 'invoicePayment',
                    applyFilter: true,
                    filters: [
                        { id: 'paid', value: 'false' },
                    ],
                    serverFilters: [
                        { id: 'paymentDate', value: '{today}' }
                    ]
                },
                {
                    filterName: 'invoice.list.filter.3',
                    entity: 'invoice',
                    applyFilter: true,
                    filters: [
                        { id: 'state', value: 'final' },
                        { id: 'archived', value: 'true' }
                    ],
                    filterSlug: 'archived'
                }
            ],
            marginTop: '1rem'
        },
        tables: {
            invoice: {
                title: 'invoice.list.table.title',
                searchLabel: 'all.list.search',
                searchPlaceholder: 'all.list.search.placeholder',
                orderBy: 'bookedNumber',
                tableKey: 'id',
                columns: [{
                    id: 'bookedNumber',
                    name: 'invoice.list.table.number',
                    length: '100px'
                },
                {
                    id: 'customerName',
                    name: 'invoice.list.table.customerName',
                    length: '250px'
                },
                {
                    id: 'date',
                    name: 'invoice.list.table.date',
                    length: '100px',
                    type: 'date'
                },
                {
                    id: 'totalGrossSalesAmount',
                    name: 'invoice.list.table.amount',
                    length: '150px',
                    type: 'amount',
                    decorator: 'currency'
                },
                {
                    id: 'paid',
                    name: 'Payment Status',
                    type: 'boolean',
                    values: ['PAID', 'UNPAID'],
                    length: '90px',
                }
                ],
                dropOrder: {
                    820: [0, 2, 4],
                    985: [2, 4],
                    1100: [4]
                },
                mobile: {
                    leftTag: { field: 'customerName' },
                    leftSubTag: { field: 'bookedNumber' },
                    rightTag: { field: 'totalGrossSalesAmount', type: 'amount' },
                    rightSubTag: { field: 'date', type: 'date' },
                    leftBadge: null,
                    rightArrow: null,
                    leftIcon: Icon_Invoice,
                    rightIcon: Icon_Arrow_Right
                }
            },
            invoicePayment: {
                title: 'invoicePayment.list.table.title',
                searchLabel: 'all.list.search',
                searchPlaceholder: 'all.list.search.placeholder',
                orderBy: 'paymentDate',
                orderDirection: 'desc',
                tableKey: 'invoiceId',
                path: '/invoice/',
                columns: [{
                    id: 'invoiceNumber',
                    name: 'invoicePayment.list.table.invoice.number',
                    length: '150px'
                },
                {
                    id: 'customerName',
                    name: 'invoicePayment.list.table.customer.name',
                    length: '350px'
                },
                {
                    id: 'paymentDate',
                    name: 'invoicePayment.list.table.payment.date',
                    length: '150px',
                    type: 'date'
                },
                {
                    id: 'paymentAmount',
                    name: 'invoicePayment.list.table.amount',
                    length: '100px',
                    type: 'amount',
                    decorator: 'currency'
                }
                ],
                dropOrder: {
                    800: [1, 2],
                    1024: [2]
                },
                mobile: {
                    leftTag: { field: 'customerName' },
                    leftSubTag: { field: 'invoiceNumber' },
                    rightTag: { field: 'paymentAmount', type: 'amount' },
                    rightSubTag: { field: 'paymentDate', type: 'date' },
                    leftBadge: null,
                    rightArrow: null,
                    leftIcon: <BsIcons.BsBoxes />,
                    rightIcon: <AiIcons.AiOutlineRight />
                }
            }
        }
    },
    creditNoteList: {
        title: {
            text: 'creditNote.list.title',
            createButton: 'creditNote.list.new',
            entity: 'invoice',
            newPath: 'new'
        },
        entity: 'creditNote',
        endpoint: 'creditNote',
        filters: {
            list: [
                {
                    filterName: 'creditNote.list.filter.1',
                    entity: 'creditNote',
                    applyFilter: true,
                    filters: [
                        { id: 'state', value: 'draft' },
                        { id: 'archived', value: 'false' }
                    ],
                    filterSlug: 'draft'
                },
                {
                    filterName: 'creditNote.list.filter.2',
                    entity: 'creditNote',
                    applyFilter: true,
                    filters: [
                        { id: 'state', value: 'final' },
                        { id: 'archived', value: 'false' }
                    ],
                    filterSlug: 'booked'
                },
                {
                    filterName: 'creditNote.list.filter.3',
                    entity: 'creditNote',
                    applyFilter: true,
                    filters: [
                        { id: 'state', value: 'final' },
                        { id: 'archived', value: 'true' }
                    ],
                    filterSlug: 'archived'
                }
            ],
            marginTop: '1rem'
        },
        tables: {
            creditNote: {
                title: 'creditNote.list.table.title',
                searchLabel: 'all.list.search',
                searchPlaceholder: 'all.list.search.placeholder',
                orderBy: 'bookedNumber',
                tableKey: 'id',
                columns: [{
                    id: 'bookedNumber',
                    name: 'creditNote.list.table.number',
                    length: '100px'
                },
                {
                    id: 'customerName',
                    name: 'creditNote.list.table.customerName',
                    length: '250px'
                },
                {
                    id: 'date',
                    name: 'creditNote.list.table.date',
                    length: '100px',
                    type: 'date'
                },
                {
                    id: 'totalGrossSalesAmount',
                    name: 'creditNote.list.table.amount',
                    length: '150px',
                    type: 'amount',
                    decorator: 'currency'
                },
                {
                    id: 'paid',
                    name: 'Payment Status',
                    type: 'boolean',
                    values: ['PAID', 'UNPAID'],
                    length: '90px',
                }
                ],
                dropOrder: {
                    820: [0, 2, 4],
                    985: [2, 4],
                    1100: [4]
                },
                mobile: {
                    leftTag: { field: 'customerName' },
                    leftSubTag: { field: 'bookedNumber' },
                    rightTag: { field: 'totalGrossSalesAmount', type: 'amount' },
                    rightSubTag: { field: 'date', type: 'date' },
                    leftBadge: null,
                    rightArrow: null,
                    leftIcon: Icon_Invoice,
                    rightIcon: Icon_Arrow_Right
                }
            }
        }
    },
    paymentTermList: {
        title: {
            text: 'paymentTerm.list.title',
            createButton: 'paymentTerm.list.new',
            newPath: 'new'
        },
        entity: 'paymentTerm',
        endpoint: 'paymentTerm',
        orderBy: 'name',
        showNavigation: true,
        filters: {
            list: [
            ],
            marginTop: '1rem'
        },
        tables: {
            paymentTerm: {
                searchLabel: 'all.list.search',
                searchPlaceholder: 'all.list.search.placeholder',
                orderBy: 'name',
                columns: [
                    {
                        id: 'id',
                        name: 'paymentTerm.list.table.id',
                        length: '150px',
                        type: 'string'
                    },
                    {
                        id: 'name',
                        name: 'paymentTerm.list.table.name',
                        length: '150px',
                        type: 'string'
                    },
                ],
                mobile: {
                    leftTag: {
                        field: 'name',
                        type: 'string'
                    },
                    leftSubTag: {
                        field: 'email'
                    },
                    rightSubTag: null,
                    leftBadge: null,
                    rightArrow: null,
                    leftIcon: <IoIcons.IoMdPerson />,
                    rightIcon: <AiIcons.AiOutlineRight />
                }
            }
        }
    },
    warehouseList: {
        title: {
            text: 'warehouse.list.title',
            createButton: 'warehouse.list.new',
            newPath: 'new'
        },
        entity: 'warehouse',
        endpoint: 'warehouse',
        showNavigation: true,
        filters: {
            list: [
            ],
            marginTop: '1rem'
        },
        tables: {
            warehouse: {
                searchLabel: 'all.list.search',
                searchPlaceholder: 'all.list.search.placeholder',
                orderBy: 'name',
                columns: [
                    {
                        id: 'name',
                        name: 'warehouse.list.table.name',
                        length: '150px',
                        type: 'string'
                    },
                    {
                        id: 'countryName',
                        name: 'warehouse.list.table.countryName',
                        length: '150px',
                        type: 'string'
                    }
                ],
                mobile: {
                    leftTag: {
                        field: 'name',
                        type: 'string'
                    },
                    leftSubTag: {
                        field: 'countryName'
                    },
                    rightSubTag: null,
                    leftBadge: null,
                    rightArrow: null,
                    leftIcon: <IoIcons.IoMdPerson />,
                    rightIcon: <AiIcons.AiOutlineRight />
                }
            }
        }
    },
    hallList: {
        title: {
            text: 'warehouse.hall.list.title',
            createButton: 'warehouse.hall.list.new',
            newPath: 'hall/new'
        },
        entity: 'hall',
        endpoint: 'warehouse/:id/hall',
        subRoute: true,
        hideNavigation: true,
        filters: {
            list: [
            ],
            marginTop: '1rem'
        },
        tables: {
            hall: {
                searchLabel: 'all.list.search',
                searchPlaceholder: 'all.list.search.placeholder',
                orderBy: 'name',
                path: 'hall/',
                columns: [
                    {
                        id: 'name',
                        name: 'warehouse.hall.list.table.name',
                        length: '150px',
                        type: 'string'
                    }
                ],
                mobile: {
                    leftTag: {
                        field: 'name',
                        type: 'string'
                    },
                    leftSubTag: {
                        field: 'warehouseName'
                    },
                    rightSubTag: null,
                    leftBadge: null,
                    rightArrow: null,
                    leftIcon: <IoIcons.IoMdPerson />,
                    rightIcon: <AiIcons.AiOutlineRight />
                }
            }
        }
    },
    itemLocationList: {
        title: {
            //text: 'product.location.list.title',
            //createButton: 'product.location.list.new',
        },
        entity: 'itemLocation',
        endpoint: 'product/:id/itemLocation',
        subRoute: true,
        hideNavigation: true,
        filters: {
            list: [
            ],
            marginTop: '1rem'
        },
        tables: {
            itemLocation: {
                searchLabel: 'all.list.search',
                searchPlaceholder: 'all.list.search.placeholder',
                orderBy: 'locationId',
                path: '/location/',
                columns: [
                    {
                        id: 'locationId',
                        name: 'product.location.list.table.location',
                        length: '150px',
                        type: 'string'
                    },
                    {
                        id: 'quantity',
                        name: 'product.location.list.table.quantity',
                        length: '150px',
                        type: 'string',
                        decorator: 'unitName'
                    },
                    {
                        id: 'availableQuantity',
                        name: 'product.location.list.table.quantity.available',
                        length: '150px',
                        type: 'string',
                        decorator: 'unitName'

                    },
                    {
                        id: 'reservedQuantity',
                        name: 'product.location.list.table.quantity.reserved',
                        length: '150px',
                        type: 'quantity',
                        decorator: 'unitName'
                    },
                ],
                mobile: {
                    leftTag: {
                        field: 'quantity',
                        type: 'string'
                    },
                    leftSubTag: {
                        field: 'locationId',
                        type: 'string'
                    },
                    rightSubTag: null,
                    leftBadge: null,
                    rightArrow: null,
                    leftIcon: <IoIcons.IoMdPerson />,
                    rightIcon: <AiIcons.AiOutlineRight />
                }
            }
        }
    },
    rackList: {
        title: {
            text: 'rack.list.title',
            createButton: 'rack.list.new',
            newPath: 'new'
        },
        entity: 'rack',
        endpoint: 'rack',
        showNavigation: true,
        filters: {
            list: [
            ],
            marginTop: '1rem'
        },
        tables: {
            rack: {
                searchLabel: 'all.list.search',
                searchPlaceholder: 'all.list.search.placeholder',
                orderBy: 'code',
                columns: [
                    {
                        id: 'code',
                        name: 'rack.list.table.name',
                        length: '150px',
                        type: 'string'
                    },
                    {
                        id: 'warehouseName',
                        name: 'rack.list.table.warehouseName',
                        length: '150px',
                        type: 'string'
                    },
                    {
                        id: 'hallName',
                        name: 'rack.list.table.hallName',
                        length: '150px',
                        type: 'string'
                    }
                ],
                mobile: {
                    leftTag: {
                        field: 'name',
                        type: 'string'
                    },
                    leftSubTag: {
                        field: 'warehouseName'
                    },
                    rightSubTag: null,
                    leftBadge: null,
                    rightArrow: null,
                    leftIcon: <IoIcons.IoMdPerson />,
                    rightIcon: <AiIcons.AiOutlineRight />
                }
            }
        }
    },
    locationList: {
        title: {
            text: 'location.list.title',
            //createButton: 'location.list.new',
            newPath: 'new'
        },
        entity: 'location',
        endpoint: 'location',
        showNavigation: true,
        filters: {
            list: [
            ],
            marginTop: '1rem'
        },
        tables: {
            location: {
                searchLabel: 'all.list.search',
                searchPlaceholder: 'all.list.search.placeholder',
                orderBy: 'warehouseCode',
                columns: [
                    {
                        id: 'id',
                        name: 'location.list.table.binName',
                        length: '150px',
                        type: 'string'
                    },
                    {
                        id: 'warehouseCode',
                        name: 'location.list.table.warehouseCode',
                        length: '150px',
                        type: 'string'
                    },
                    {
                        id: 'hallCode',
                        name: 'location.list.table.hallCode',
                        length: '150px',
                        type: 'string'
                    },
                    {
                        id: 'rackCode',
                        name: 'location.list.table.rackCode',
                        length: '150px',
                        type: 'string'
                    }
                ],
                mobile: {
                    leftTag: {
                        field: 'id',
                        type: 'string'
                    },
                    leftSubTag: {
                        field: 'warehouseCode'
                    },
                    rightSubTag: null,
                    leftBadge: null,
                    rightArrow: null,
                    leftIcon: <IoIcons.IoMdPerson />,
                    rightIcon: <AiIcons.AiOutlineRight />
                }
            }
        }
    },
    stockAdjustmentList: {
        title: {
            text: 'inventory.adjustment.list.title',
            createButton: 'inventory.adjustment.list.new',
            entity: 'stockAdjustment',
            newPath: 'new'
        },
        entity: 'stockAdjustment',
        endpoint: 'stockAdjustment',
        filters: {
            list: [
                {
                    filterName: 'inventory.adjustment.list.filter.draft',
                    entity: 'stockAdjustment',
                    applyFilter: true,
                    filters: [
                        { id: 'state', value: 'draft' },
                    ],
                    filterSlug: 'draft'
                },
                {
                    filterName: 'inventory.adjustment.list.filter.booked',
                    entity: 'stockAdjustment',
                    applyFilter: true,
                    filters: [
                        { id: 'state', value: 'final' },
                    ],
                    filterSlug: 'booked'
                }
            ],
            marginTop: '1rem'
        },
        tables: {
            stockAdjustment: {
                title: 'inventory.adjustment.list.table.title',
                searchLabel: 'all.list.search',
                searchPlaceholder: 'all.list.search.placeholder',
                orderBy: 'date',
                tableKey: 'id',
                columns: [
                    {
                        id: 'bookedNumber',
                        name: 'inventory.adjustment.list.table.number',
                        length: '100px',
                        type: 'bookedNumber'
                    },
                    {
                        id: 'date',
                        name: 'inventory.adjustment.list.table.date',
                        length: '100px',
                        type: 'date'
                    },
                    {
                        id: 'totalProducts',
                        name: 'inventory.adjustment.list.table.totalProducts',
                        length: '100px',
                    },
                    {
                        id: 'note',
                        name: 'inventory.adjustment.list.table.note',
                        length: '250px'
                    }
                ],
                dropOrder: {
                    820: [0, 2, 4],
                    985: [2, 4],
                    1100: [4]
                },
                mobile: {
                    leftTag: { field: 'bookedNumber' },
                    leftSubTag: { field: 'date', type: 'date' },
                    rightTag: { field: 'totalProducts' },
                    rightSubTag: { field: 'note' },
                    leftBadge: null,
                    rightArrow: null,
                    leftIcon: Icon_StockAdjustment,
                    rightIcon: Icon_Arrow_Right
                }
            },
        }
    },
    stockMovementList: {
        title: {
            text: 'inventory.movement.list.title',
            createButton: 'inventory.movement.list.new',
            entity: 'stockMovement',
            newPath: 'new'
        },
        entity: 'stockMovement',
        endpoint: 'stockMovement',
        filters: {
            list: [
                {
                    filterName: 'inventory.movement.list.filter.draft',
                    entity: 'stockMovement',
                    applyFilter: true,
                    filters: [
                        { id: 'state', value: 'draft' },
                    ],
                    filterSlug: 'draft'
                },
                {
                    filterName: 'inventory.movement.list.filter.booked',
                    entity: 'stockMovement',
                    applyFilter: true,
                    filters: [
                        { id: 'state', value: 'final' },
                    ],
                    filterSlug: 'booked'
                }
            ],
            marginTop: '1rem'
        },
        tables: {
            stockMovement: {
                title: 'inventory.movement.list.table.title',
                searchLabel: 'all.list.search',
                searchPlaceholder: 'all.list.search.placeholder',
                orderBy: 'date',
                tableKey: 'id',
                columns: [
                    {
                        id: 'number',
                        name: 'inventory.movement.list.table.number',
                        length: '100px',
                        type: 'bookedNumber'
                    },
                    {
                        id: 'date',
                        name: 'inventory.movement.list.table.date',
                        length: '100px',
                        type: 'date'
                    },
                    {
                        id: 'note',
                        name: 'inventory.movement.list.table.notes',
                        length: '250px'
                    }

                ],
                dropOrder: {
                    820: [0, 2, 4],
                    985: [2, 4],
                    1100: [4]
                },
                mobile: {
                    leftTag: { field: 'bookedNumber' },
                    leftSubTag: { field: 'date', type: 'date' },
                    rightTag: { field: 'totalProducts' },
                    rightSubTag: { field: 'note' },
                    leftBadge: null,
                    rightArrow: null,
                    leftIcon: Icon_StockMovement,
                    rightIcon: Icon_Arrow_Right
                }
            },
        }
    },
    stockShipmentList: {
        title: {
            text: 'inventory.shipment.list.title',
            createButton: 'inventory.shipment.list.new',
            entity: 'stockShipment',
            newPath: 'new'
        },
        entity: 'stockShipment',
        endpoint: 'stockShipment',
        filters: {
            list: [
                {
                    filterName: 'inventory.shipment.list.filter.draft',
                    entity: 'stockShipment',
                    applyFilter: true,
                    filters: [
                        { id: 'state', value: 'draft' },
                    ],
                    filterSlug: 'draft'
                },
                {
                    filterName: 'inventory.shipment.list.filter.booked',
                    entity: 'stockShipment',
                    applyFilter: true,
                    filters: [
                        { id: 'state', value: 'final' },
                    ],
                    filterSlug: 'booked'
                }
            ],
            marginTop: '1rem'
        },
        tables: {
            stockShipment: {
                title: 'inventory.shipment.list.table.title',
                searchLabel: 'all.list.search',
                searchPlaceholder: 'all.list.search.placeholder',
                orderBy: 'date',
                tableKey: 'id',
                columns: [
                    {
                        id: 'number',
                        name: 'inventory.shipment.list.table.number',
                        length: '100px',
                        type: 'bookedNumber'
                    },
                    {
                        id: 'date',
                        name: 'inventory.shipment.list.table.date',
                        length: '100px',
                        type: 'date'
                    },
                    {
                        id: 'note',
                        name: 'inventory.shipment.list.table.notes',
                        length: '250px'
                    }

                ],
                dropOrder: {
                    820: [0, 2, 4],
                    985: [2, 4],
                    1100: [4]
                },
                mobile: {
                    leftTag: { field: 'bookedNumber' },
                    leftSubTag: { field: 'date', type: 'date' },
                    rightTag: { field: 'totalProducts' },
                    rightSubTag: { field: 'note' },
                    leftBadge: null,
                    rightArrow: null,
                    leftIcon: Icon_Shipment,
                    rightIcon: Icon_Arrow_Right
                }
            },
        }
    },
    stockReceiptList: {
        title: {
            text: 'inventory.receipt.list.title',
            createButton: 'inventory.receipt.list.new',
            entity: 'stockReceipt',
            newPath: 'new'
        },
        entity: 'stockReceipt',
        endpoint: 'stockReceipt',
        filters: {
            list: [
                {
                    filterName: 'inventory.receipt.list.filter.draft',
                    entity: 'stockReceipt',
                    applyFilter: true,
                    filters: [
                        { id: 'state', value: 'draft' },
                    ],
                    filterSlug: 'draft'
                },
                {
                    filterName: 'inventory.receipt.list.filter.booked',
                    entity: 'stockReceipt',
                    applyFilter: true,
                    filters: [
                        { id: 'state', value: 'final' },
                    ],
                    filterSlug: 'booked'
                }
            ],
            marginTop: '1rem'
        },
        tables: {
            stockReceipt: {
                title: 'inventory.receipt.list.table.title',
                searchLabel: 'all.list.search',
                searchPlaceholder: 'all.list.search.placeholder',
                orderBy: 'date',
                tableKey: 'id',
                columns: [
                    {
                        id: 'number',
                        name: 'inventory.receipt.list.table.number',
                        length: '100px',
                        type: 'bookedNumber'
                    },
                    {
                        id: 'date',
                        name: 'inventory.receipt.list.table.date',
                        length: '100px',
                        type: 'date'
                    },
                    {
                        id: 'note',
                        name: 'inventory.receipt.list.table.notes',
                        length: '250px'
                    }
                ]
            },
        },
        dropOrder: {
            820: [0, 2, 4],
            985: [2, 4],
            1100: [4]
        },
        mobile: {
            leftTag: { field: 'bookedNumber' },
            leftSubTag: { field: 'date', type: 'date' },
            rightTag: { field: 'totalProducts' },
            rightSubTag: { field: 'note' },
            leftBadge: null,
            rightArrow: null,
            leftIcon: Icon_Receipt,
            rightIcon: Icon_Arrow_Right
        }

    },
    locationItemSubList: {
        title: {
            text: 'product.list.title',
            createButton: 'product.list.new',
            newPath: 'new'
        },
        entity: 'product',
        endpoint: 'product/:id',
        array: 'inventory',
        filters: {
            list: [
                { filterName: 'product.list.filter.1' },
                { filterName: 'product.list.filter.2' },
                { filterName: 'product.list.filter.3' }
            ],
            marginTop: '1rem'
        },
        tables: {
            product: {
                title: 'product.list.table.title',
                searchLabel: 'all.list.search',
                searchPlaceholder: 'all.list.search.placeholder',
                orderBy: 'name',
                tableKey: 'itemId',
                path: '/product/',
                columns: [{
                    id: 'itemName',
                    name: 'Product',
                    length: '200px'
                },
                {
                    id: 'itemDescription',
                    name: 'Description',
                    length: '250px'
                },
                {
                    id: 'quantity',
                    name: 'Quantity',
                    length: '75px',
                    decorator: 'unitName'
                },
                {
                    id: 'availableQuantity',
                    name: 'Available',
                    length: '75px',
                    decorator: 'unitName'
                },
                {
                    id: 'reservedQuantity',
                    name: 'Reserved',
                    length: '50px',
                    type: 'quantity',
                    decorator: 'unitName'
                },

                ],
                dropOrder: {
                    800: [1, 2],
                    1024: [2]
                },
                mobile: {
                    leftTag: { field: 'itemName' },
                    leftSubTag: { field: 'availableQuantity', decorator: 'unitName' },
                    rightTag: { field: 'quantity', decorator: 'unitName' },
                    rightSubTag: null,
                    leftBadge: null,
                    rightArrow: null,
                    leftIcon: <BsIcons.BsBoxes />,
                    rightIcon: <AiIcons.AiOutlineRight />
                }
            }
        }
    },
    locationReservationSubList: {
        title: {
            text: 'product.list.title',
            createButton: 'product.list.new',
            newPath: 'new'
        },
        entity: 'reservation',
        endpoint: 'reservation',
        array: 'reservations',
        filters: {
            list: [
                { filterName: 'product.list.filter.1' },
                { filterName: 'product.list.filter.2' },
                { filterName: 'product.list.filter.3' }
            ],
            marginTop: '1rem'
        },
        tables: {
            reservation: {
                title: 'product.list.table.title',
                searchLabel: 'all.list.search',
                searchPlaceholder: 'all.list.search.placeholder',
                orderBy: 'name',
                tableKey: 'reservationId',
                path: '/reservation/',
                columns: [
                    {
                        id: 'reservationId',
                        name: 'Res Id',
                        length: '250px',
                        type: 'code7'
                    },
                    {
                        id: 'itemName',
                        name: 'Product',
                        length: '150px'
                    },
                    {
                        id: 'quantity',
                        name: 'Reserved',
                        length: '100px'
                    },
                    {
                        id: 'activeDocument',
                        name: 'Doc',
                        length: '150px',
                        type: 'label'
                    },
                    {
                        id: 'documentId',
                        name: 'Document Id',
                        length: '250px',
                        type: 'code7'
                    }
                ],
                dropOrder: {
                    800: [1, 2],
                    1024: [2]
                },
                mobile: {
                    leftTag: { field: 'itemName' },
                    leftSubTag: { field: 'reservationId', type: 'code7' },
                    rightTag: { field: 'quantity' },
                    rightSubTag: null,
                    leftBadge: null,
                    rightArrow: null,
                    leftIcon: <BsIcons.BsBoxes />,
                    rightIcon: <AiIcons.AiOutlineRight />
                }
            }
        }
    }
}

export default listViewData