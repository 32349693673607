import { useState, useCallback } from 'react';
import styled from 'styled-components';
import useTranslation from "../../hooks/useTranslation";
import Checkbox from '../generic/CheckBox';

import { Tag } from '../generic/Core';
import { Link } from 'react-router-dom';


const ListContainer = styled.div`
    width: 100%;
    padding: 0rem;
`;

export const ListHeader = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    border-bottom: 1px solid ${props => props.theme.table.border};
    font-weight: bold;
`;

export const ListRow = styled(Link)`
    display: flex;
    min-height: 3rem;
    justify-content: space-between;
    padding: 0.5rem;
    border-bottom: 1px solid ${props => props.theme.table.border};
    cursor: pointer;
    background-color: ${props => props.selected ? props.theme.table.background : 'transparent'};
    
    &:hover {
        background-color: ${props => props.theme.table.hover};
    }
`;

export const ListCol = styled.div`
    flex: ${props => props.$flex || '1'};
    margin: ${props => props.$margin || '0'}; 
    display: flex;
    align-items: center;
    font-size: var(--font-XS);
`;


function getReservedQuantity(data, docId, lineId) {
    return data.reservations
      .filter(reservation => reservation.documentId === docId && Number(reservation.lineId.split('.')[0]) === Number(lineId))
      .reduce((sum, reservation) => sum + reservation.quantity, 0);
  }

const filterItems = (data, docId, lineId) => {
    const itemsAvailable = [];
    const itemsReserved = [];

    data.forEach(item => {
        console.log("ITEM:", item);
        console.log("DATA:", data);
        console.log("LINEID:", lineId);


        if (item.reservedQuantity > 0 && item.reservations.some((reservation) => {
            console.log("Reservation LineId:", reservation.lineId.split('.')[0]);
            return (
                reservation.documentId === docId && Number(reservation.lineId.split('.')[0]) === Number(lineId)
            )
        })) {

            //find out the actual reserved quantity
            const displayQuantity = getReservedQuantity(item, docId, lineId);
            item.displayQuantity = displayQuantity;

            itemsReserved.push(item);
        } else {
            if (item.availableQuantity > 0) {
                itemsAvailable.push(item);
            }
        }



    });

    return { itemsAvailable, itemsReserved };
};




const MultiList = ({
    data = [],
    onSelectionChange,
    sumField,
    constraint,
    filter,
    columns = ['Location', 'Quantity'],
    existingSelections = [],
    emptyMessage,
    lineId,
    docId
}) => {

    const result = filterItems(data, docId, lineId);

    //    console.log("Items Available:", result.itemsAvailable);
    //    console.log("Items Reserved:", result.itemsReserved);
    //    console.log("EXISTING SELECTIONS:", existingSelections);
    //    console.log("sumField:", sumField);

    const availData = result.itemsAvailable;
    const resData = result.itemsReserved;
    //const editData = data.filter(item => item[filter] === undefined || item[filter] === 0);  
    //const viewData = data.filter(item => item[filter] > 0);

    const { t } = useTranslation()

    // Initialize selectedItems with matches from existingSelections
    const initialSelectedItems = data.filter(item =>
        existingSelections.some(selection =>
            selection.locationId === item.locationId
        )
    );

    const [selectedItems, setSelectedItems] = useState(initialSelectedItems);

    // Calculate total sum without useEffect
    const calculateTotalSum = useCallback((items) => {
        return items.reduce((acc, item) => {
            const itemValue = Number(item[sumField] || 0);
            const remainingConstraint = constraint - acc;
            return acc + Math.min(itemValue, remainingConstraint);
        }, 0);
    }, [sumField, constraint]);

    const getItemAllocation = (item) => {
        const existingSelection = existingSelections.find(
            selection => selection.locationId === item.locationId
        );
        return existingSelection ? existingSelection.quantity : 0;
    };

    const handleToggleSelection = (item) => {
        let newSelectedItems;
        const isSelected = selectedItems.some(selected => selected.id === item.id);

        if (isSelected) {
            newSelectedItems = selectedItems.filter(selected => selected.id !== item.id);
        } else {
            newSelectedItems = [...selectedItems, item];
        }

        // Always update both local state and parent
        setSelectedItems(newSelectedItems);

        onSelectionChange(newSelectedItems);
    };

    const isItemSelectable = (item) => {
        // If item is already selected, allow deselection
        if (selectedItems.some(selected => selected.id === item.id)) {
            return true;
        }

        // If we've already allocated the full constraint, prevent further selection
        const currentSum = calculateTotalSum(selectedItems);
        if (currentSum >= constraint) {
            return false;
        }

        return true;
    };

    return (
        <ListContainer>
            <ListHeader>
                {columns.map((col, key) => (
                    <ListCol $flex={col.flex} $margin={col.margin} key={key}>
                        {t(col.title)}
                    </ListCol>
                ))}
            </ListHeader>
            {(availData.length === 0 && resData.length === 0) &&
                <ListRow><span>{t(emptyMessage)}</span></ListRow>
            }
            {availData.map((item, key) => (
                <ListRow
                    key={key}
                    selected={selectedItems.some(selected => selected.id === item.id)}
                    onClick={() => isItemSelectable(item) && handleToggleSelection(item)}
                >
                    {columns.map((col, key) => (
                        <ListCol $flex={col.flex} $margin={col.margin} key={key}>
                            {col.check && <Checkbox
                                $margin='0 1rem 0 0'
                                type="checkbox"
                                checked={selectedItems.some(selected => selected.id === item.id)}
                                onChange={() => isItemSelectable(item) && handleToggleSelection(item)}
                                disabled={!isItemSelectable(item)}
                            />}
                            {item[col.field]} {item[col.deco]}
                            {(selectedItems.some(selected => selected.id === item.id)) && col.tag &&
                                <Tag $variant='primary'>{getItemAllocation(item)}</Tag>
                            }
                        </ListCol>
                    ))}
                </ListRow>
            ))}
            {resData.map((item, key) => (
                <ListRow
                    key={key}
                    selected={selectedItems.some(selected => selected.id === item.id)}
                >

                    {columns.map((col, key) => (
                        <ListCol $flex={col.flex} $margin={col.margin} key={key}>
                            {(key === 0) ? <Tag $margin='0 1rem 0 0' $variant='primary'>R</Tag> : <></>}
                            {item[col.field]} {item[col.deco]} {t(col.label)} 
                            {col.tag && <Tag $variant='primary'>{item.displayQuantity}</Tag>}
                        </ListCol>
                    ))}
                </ListRow>
            ))}

        </ListContainer>
    );
};

export default MultiList;
