import useTranslation from "../../hooks/useTranslation"
import styled from "styled-components"
import useFormatting from "../../hooks/useFormatting"

const StyledSpan = styled.span`
    color: ${({ color, theme }) => color === 'true' ? theme.badge.true : theme.badge.false};
    background-color: ${({ theme }) => theme.badge.bg};
    padding: 0px 5px;
    border-radius: 10px;
    font-weight: 700;
    font-size: var(--font-XXS);
    font-family: "Prompt", sans-serif;
`

const DisplayElement = ({ string, type = '', item, col, prefix = null }) => {
    const { t } = useTranslation()   
    const { formatString } = useFormatting()
    
    let result = formatString(string, type, item, col)

   

    if (prefix) result = t(prefix) + result

    return (
        <>
            {type === 'boolean' && <StyledSpan color={item[col.id] ? 'true' : 'false'} className="prompt">{result}</StyledSpan>}
            {type !== 'boolean' && <span>{result}</span>}
        </>
    )

}

export default DisplayElement

