import styled from "styled-components"
import { widgetData } from "../../model/components/widgetData"

import TextWidget from "./widgets/TextWidget"
import ListWidget from "./widgets/ListWidget"
import ChartWidget from "./widgets/ChartWidget"

const WidgetWrapper = styled.div`
  overflow: hidden;
  display: inline-block;
  height: 100%;
  width:100%;

`

const WidgetSwitcher = ({ type }) => {

  const config = widgetData[type]
  if (!config) return (
    <p>Widget Not Found!</p>
  )

  return (
    <WidgetWrapper>
      {config.widgetType === 'TextWidget' ? <TextWidget data={config.widgetData} type={type} labels={config.widgetLabels} /> : <></>}
      {config.widgetType === 'ListWidget' ? <ListWidget data={config.widgetData} type={type} labels={config.widgetLabels} /> : <></>}
      {config.widgetType === 'BarChartWidget' ? <ChartWidget widgetData={config.widgetData} type={type} labels={config.widgetLabels} /> : <></>} 
    </WidgetWrapper>
  )
}

export default WidgetSwitcher