const texts = {
    'sales': [
        'app/sales', 
        'cmp/product', 
        'cmp/contact', 
        'cmp/select'
    ],
    'crm':[
        'app/crm', 
        'cmp/contact', 
        'cmp/product', 
        'cmp/select'
    ],
    'service':['app/service', 'cmp/select'],
    'home':['app/home', 'cmp/select'],
    'org':['app/org', 'cmp/select'],
    'entry':['app/entry', 'cmp/select'],
    'hello':['app/hello', 'cmp/select'],
    'inventory':['app/inventory', 'cmp/product', 'cmp/contact'], 
    'purchases':['app/purchases', 'cmp/product', 'cmp/contact'],
    'bank':['app/bank', 'cmp/contact'],   
}

export default texts