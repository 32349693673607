import React from 'react';
import ReservationList from '../ReservationList';
import { tabViewData } from '../../../model/components/tabViewData';


// Component mapping object
const componentMap = {
    ReservationList
};


// Function to merge static config with runtime data
export const getTabsConfig = ({ data, reservations,section, useStore }) => {

    const tabList = tabViewData[section.meta.tabList].tabs


    const componentProps = (tab) => ({ 
            data,
            reservations,
            section, 
            useStore,
            id: tab.id
        })

    return tabList.map(tab => ({
        ...tab,
        content: React.createElement(
            componentMap[tab.component],
            componentProps(tab)
        )
    }));
};
