import * as AiIcons from 'react-icons/ai'
import * as IoIcons from 'react-icons/io'
import * as BsIcons from 'react-icons/bs'
import * as GoIcons from 'react-icons/go'
import * as TbIcons from "react-icons/tb";
import * as HiIcons from "react-icons/hi";
import * as SiIcons from "react-icons/si";
import * as LiaIcons from "react-icons/lia";
import * as RxIcons from "react-icons/rx";
import * as PiIcons from "react-icons/pi";
import * as LuIcons from "react-icons/lu";

import customIcons from './customIcons'

import * as DiIcons from "react-icons/di";
import * as FiIcons from "react-icons/fi";
import * as FcIcons from "react-icons/fc";
import * as Hi2Icons from "react-icons/hi2";
import * as Io5Icons from "react-icons/io5";
import * as SlIcons from "react-icons/sl";
import * as TfIcons from "react-icons/tfi";
import * as CgIcons from "react-icons/cg";

// Apache License Version 2.0
import * as MdIcons from 'react-icons/md'
import * as GrIcons from "react-icons/gr";
import * as RiIcons from "react-icons/ri";

//DO NOT USE:
import * as biIcons from "react-icons/bi";
import * as ciIcons from "react-icons/ci";
import * as faIcons from "react-icons/fa";
import * as giIcons from "react-icons/gi";
import * as imIcons from "react-icons/im";
import * as siIcons from "react-icons/si";
import * as tiIcons from "react-icons/ti";
import * as vsIcons from "react-icons/vsc";
import * as wiIcons from "react-icons/wi";


// Icons for Navigation
export const Icon_Apps = <MdIcons.MdOutlineApps />
export const Icon_Download = <MdIcons.MdDownload />
export const Icon_Org = <BsIcons.BsBuilding />
export const Icon_People=  <Hi2Icons.HiOutlineUsers />
export const Icon_Gear =  <GoIcons.GoGear />
export const Icon_Back = <IoIcons.IoMdArrowBack />
export const Icon_Home = <GoIcons.GoHome />
export const Icon_Boxes = <BsIcons.BsBoxes />
export const Icon_Paper = <Io5Icons.IoDocumentOutline />
export const Icon_Message = <BsIcons.BsEnvelopeOpenFill />
export const Icon_Help = <IoIcons.IoMdHelpCircle />
export const Icon_Cart = <MdIcons.MdLocalGroceryStore />
export const Icon_Invoice = <LiaIcons.LiaFileInvoiceDollarSolid />

// Icons for Apps List
export const Icon_PeopleFill = <BsIcons.BsFillPeopleFill />
export const Icon_Factory = <TbIcons.TbBuildingFactory2 />
export const Icon_Books = <SiIcons.SiBookstack />
export const Icon_CustomerService =<AiIcons.AiFillCustomerService />
export const Icon_Inventory = <MdIcons.MdInventory />
export const Icon_Task = <MdIcons.MdTask />

export const Icon_Arrow_Right = <AiIcons.AiOutlineRight />
export const Icon_Arrow_Left = <AiIcons.AiOutlineLeft />
export const Icon_Edit = <AiIcons.AiFillEdit />
export const Icon_Plus = <HiIcons.HiPlus />
export const Icon_Search = <MdIcons.MdSearch />
export const Icon_Alert = <GoIcons.GoAlertFill />
export const Icon_Delete = <MdIcons.MdDeleteForever />

export const Icon_Arrow_Left_Circle = <BsIcons.BsArrowLeftCircleFill />
export const Icon_Arrow_Right_Circle = <BsIcons.BsArrowRightCircleFill />
export const Icon_Arrow_Left_Simple= <BsIcons.BsArrowLeft />
export const Icon_Arrow_Right_Simple = <BsIcons.BsArrowRight />

export const Icon_Design = <MdIcons.MdOutlineDesignServices />

//Icons for Uploader
export const Icon_CheckMark = <AiIcons.AiOutlineCheckCircle />
export const Icon_Upload = <AiIcons.AiOutlineCloudUpload />  
export const Icon_Clear = <MdIcons.MdClear />

//Drag Component
export const Icon_Drag = <RxIcons.RxDragHandleDots2 />


//Entry Icons
export const Icon_Check = <IoIcons.IoMdCheckmark />
export const Icon_InfoCircle = <MdIcons.MdInfo />
export const Icon_Money = <TbIcons.TbPigMoney />

export const Icon_CreditNote =<BsIcons.BsFileEarmarkMinus />
export const Icon_Interaction = <AiIcons.AiFillInteraction />

export const Icon_Briefcase = <HiIcons.HiBriefcase />
export const Icon_Contacts = <RiIcons.RiContactsBook3Line />
export const Icon_Purchases = <Io5Icons.IoPricetagsOutline /> 
export const Icon_Location = <Io5Icons.IoLocationOutline /> 
export const Icon_Warehouse = <TbIcons.TbBuildingWarehouse />
export const Icon_Rack = <MdIcons.MdShelves />
export const Icon_Hall = <PiIcons.PiWarehouse />
export const Icon_Pallet = <MdIcons.MdPallet />
export const Icon_StockMovement = <GoIcons.GoPackageDependents />
export const Icon_StockAdjustment = <LuIcons.LuPackagePlus /> 
export const Icon_Shipment = <MdIcons.MdOutlineLocalShipping />
export const Icon_Receipt = <TfIcons.TfiTruck /> 
export const Icon_Lock = <MdIcons.MdLockOutline />
export const Icon_Unlock = <MdIcons.MdLockOpen />
export const Icon_Bank = <RiIcons.RiBankLine />
