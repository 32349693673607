//Importing Key Components
import { Form } from "../form/FormComponents"
import LineElement from "./LineElement"
import IconButton from "../generic/IconButton"
import { FlexBox } from "../generic/Layout"
import { LinkText } from "../generic/Core"
import { Icon_Plus } from "../../styles/icons"
import runLogic from "../../../../common/logic/runLogic"
import useTranslation from "../../hooks/useTranslation"
import { getNestedValue, setNestedValue } from "../../utils/objects/nestedValue"


import { P } from "../generic/Core"

const AddItem = ({ section, clickAction }) => {
    const { t } = useTranslation()

    return (
        <FlexBox>
            <IconButton icon={Icon_Plus} $rounded $variant='primary' $size='2rem' clickAction={clickAction} />
            <LinkText onClick={clickAction}>{t(section.meta.linesAddAction)}</LinkText>
        </FlexBox>
    )
}

const CardViewFormLines = ({ toggleExpanded, section, dispatch, validated, entity, useStore, linesArray, path = [] }) => {
    const { t } = useTranslation()


    // Get full state and current context state
    const [state, setState] = useStore(s => getNestedValue(s, path))

    const toggleSection = () => {
        toggleExpanded(section.meta.name)
    }

    const addItem = () => {
        let data = { ...state }
        if (!Array.isArray(data[linesArray])) {
            console.log('An Empty Array is filled')
            data[linesArray] = []
        }
        //This simply adds an empty object to the array of lines.
        data[linesArray].push({})

        setState(data, { path })
    }

    const deleteLine = (idx) => {
        let data = { ...state }

        // The condition can prevent a line from being deleted when true.
        const condition = state[linesArray][idx][section.meta?.preventDelete?.field] > 0 || false 
        if (section.meta?.preventDelete && condition) {
            alert(t(section.meta.preventDelete.message))
            return
        }

        data[linesArray].splice(idx, 1)
        const processedData = runLogic(data, state.entity)
        console.log('[delete Line] Section name', section.meta.name)
        toggleSection()
        setState(processedData, { path })
    }

    const localDispatch = (action, data, idx) => {
        if (action === 'delete') {
            deleteLine(idx)
            setState({ savedOn: new Date() })

        } else {
            dispatch(action, data)
        }
    }

    return (
        <Form.Section name='FormSection' width={section.meta.width}>
            {
                state[linesArray] && state[linesArray].map(
                    (line, i) => <LineElement
                        key={i}
                        idx={i}
                        dispatch={localDispatch}
                        section={section}
                        validated={validated}
                        toggleSection={toggleSection}
                        entity={entity}
                        useStore={useStore}
                        linesArray={linesArray}
                        path={path}
                    />
                )
            }
            {!section.meta.noAddLine && <AddItem section={section} clickAction={addItem}></AddItem>}
            {section.meta.noAddLine && <P> {t('all.click.to.expand')}</P>}
        </Form.Section>
    )
}

export default CardViewFormLines
