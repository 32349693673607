function addMissingMonths(data, field, endYear, endMonth, numberOfMonths) {
    // Sort the data by year and month for consistency
    data.sort((a, b) => (a.year - b.year) || (a.month - b.month));
  
    const filledData = [];
    let currentYear = endYear;
    let currentMonth = endMonth;
  
    // Helper to add a zero-value entry for missing months
    const addZeroEntry = (year, month) => ({
      date: null,
      month,
      year,
      [field]: 0,
      key: `${year}-${month.toString().padStart(2, "0")}`,
    });

    // Calculate start date by going back numberOfMonths-1 from end date
    for (let i = 0; i < numberOfMonths - 1; i++) {
      currentMonth--;
      if (currentMonth < 1) {
        currentMonth = 12;
        currentYear--;
      }
    }

    // Now iterate forward through the months
    for (let i = 0; i < numberOfMonths; i++) {
      const existingEntry = data.find(
        (entry) => entry.year === currentYear && entry.month === currentMonth
      );
  
      if (existingEntry) {
        filledData.push(existingEntry);
      } else {
        filledData.push(addZeroEntry(currentYear, currentMonth));
      }
  
      // Increment month and adjust year if needed
      currentMonth++;
      if (currentMonth > 12) {
        currentMonth = 1;
        currentYear++;
      }
    }
  
    return filledData;
  }
  
export default addMissingMonths;
