import { useState } from "react"
import listViewData from "../../model/components/listViewData"
import SubListTable from "./SubListTable"
import generateEndpoint from "../../utils/generateEndpoint"

const SubList = ({ tab, data, useStore }) => {
    const [state, setState] = useStore((s) => s)
    const config = listViewData[tab.config]
    const array = config.array
    let result = { data: { pages: [] } }
    //Initializes the table we are working on.
    const [table, setTable] = useState(config.tables[config.entity])
    result.data.pages.push({ data: state[array] })

    const endpoint = generateEndpoint(config.endpoint, state.id)

    return (
        <div>
            <SubListTable
                table={table}
                config={config}
                endpoint={endpoint}
                id={state.id}
                useStore={useStore}
                result={result}
            />
        </div>
    )


}

export default SubList