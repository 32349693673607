import { useState, useCallback } from "react"
import Table from "../table/Table"
import { Row, Col } from "../generic/Layout"
import { SearchButton } from "../generic/SearchButton"

const SubListTable = ({ result, table, config, endpoint, filter, id, useStore }) => {
    const [state, setState] = useStore((s) => s)

    const [searchTerm, setSearchTerm] = useState('')
    const [visibleColumns, setVisibleColumns] = useState(table.columns);
    const [order, setOrder] = useState([table.orderBy, table.orderDirection || 'asc'])

    const sortList = useCallback((orderBy) => {
        setOrder(prevOrder => {
            if (prevOrder[0] === orderBy) {
                return [orderBy, prevOrder[1] === 'asc' ? 'desc' : 'asc'];
            }
            return [orderBy, 'asc'];
        });
    }, []);


    return (
        <>
            <Table.TitleWrapper name='TableTitleWrapper'>
                <Row>
                    <Col name='Col'>
                        <SearchButton
                            searchTerm={searchTerm}
                            setSearchTerm={setSearchTerm}
                            searchLabel={table.searchLabel}
                            searchPlaceholder={searchTerm || table.searchPlaceholder}
                        />
                    </Col>
                </Row>
            </Table.TitleWrapper>
            <Table.Content>
                <Table.Header columns={visibleColumns} order={order} sortList={sortList} />
                {result && <Table.Rows endpoint={endpoint} result={result} columns={visibleColumns} tableKey={table.tableKey} path={table.path} />}
                {result && <Table.RowsMobile endpoint={endpoint} result={result} mobile={table.mobile} tableKey={table.tableKey} path={table.path} />}
            </Table.Content>
        </>
    )

}

export default SubListTable