//import Routes
import routesHome from './viewRoutes/home'
import routesCrm from './viewRoutes/crm'
import routesService from './viewRoutes/service'
import routesSales from './viewRoutes/sales'
import routesInventory from './viewRoutes/inventory'
import routesPurchases from './viewRoutes/purchases'
import routesBank from './viewRoutes/bank'

const viewRoutes = [
    ...routesHome,
    ...routesCrm,
    ...routesSales,
    ...routesService,
    ...routesInventory,
    ...routesPurchases,
    ...routesBank
]

export default viewRoutes;