export const widgetData =
{
    "customer.list.widget": {
        widgetType: 'ListWidget',
        widgetData: {
            entity: 'customer',
            field: 'name',
            path: '/crm/customer',
        },
        widgetLabels: {
            title: 'customer.list.widget.title',
            emptyDescription: 'customer.list.widget.empty.description',
            emptyAction: 'customer.list.widget.empty.action',
        }
    },
    "contact.list.widget": {
        widgetType: 'ListWidget',
        widgetData: {
            entity: 'contact',
            field: 'name',
            path: '/sales/contact',
        },
        widgetLabels: {
            title: 'contact.list.widget.title',
            emptyDescription: 'contact.list.widget.empty.description',
            emptyAction: 'contact.list.widget.empty.action',
        }
    },
    "product.list.widget": {
        widgetType: 'ListWidget',
        widgetData: {
            entity: 'product',
            field: 'name',
            path: '/inventory/product',
        },
        widgetLabels: {
            title: 'product.list.widget.title',
            emptyDescription: 'product.list.widget.empty.description',
            emptyAction: 'product.list.widget.empty.action',
        }
    },
    "crm.create.product.widget": {
        widgetType: 'ListWidget',
        widgetData: {
            entity: 'product',
            field: 'name',
            path: '/crm/product',
        },
        widgetLabels: {
            title: 'product.list.widget.title',
            emptyDescription: 'product.list.widget.empty.description',
            emptyAction: 'product.list.widget.empty.action',
        }
    },
    "service.create.product.widget": {
        widgetType: 'ListWidget',
        widgetData: {
            entity: 'product',
            field: 'name',
            path: '/crm/product',
        },
        widgetLabels: {
            title: 'product.list.widget.title',
            emptyDescription: 'product.list.widget.empty.description',
            emptyAction: 'product.list.widget.empty.action',
        }
    },
    "go.home.widget": {
        widgetType: 'TextWidget',
        widgetData: {
            path: '/home',
        },
        widgetLabels: {
            title: 'go.home.widget.title',
            text: 'go.home.widget.text',
            button: 'go.home.widget.button',
        }
    },
    "go.service.widget": {
        widgetType: 'TextWidget',
        widgetData: {
            path: '/service',
        },
        widgetLabels: {
            title: 'go.service.widget.title',
            text: 'go.service.widget.text',
            button: 'go.service.widget.button',
        }
    },
    "go.crm.widget": {
        widgetType: 'TextWidget',
        widgetData: {
            path: '/crm',
        },
        widgetLabels: {
            title: 'go.crm.widget.title',
            text: 'go.crm.widget.text',
            button: 'go.crm.widget.button',
        }
    },
    "crm.text.widget": {
        widgetType: 'TextWidget',
        widgetData: {
        },
        widgetLabels: {
            title: 'crm.text.widget.title',
            text: 'crm.text.widget.title'
        }
    },
    "service.text.widget": {
        widgetType: 'TextWidget',
        widgetData: {
        },
        widgetLabels: {
            title: 'service.text.widget.title',
            text: 'service.text.widget.text'
        }
    },
    "inventory.bar.chart.widget": {
        widgetType: 'BarChartWidget',
        widgetData: {
            entity: 'stats',
            load: false,
            navigator: false,
            stats: 'invoicedMonthly',
            labelFormat: 'amount',
            bars: [
                { dataKey: 'Quantity', stackId: '1', labelType: 'amount', fill: 'primary', fillActive: 'primary' },  
                { dataKey: 'Reserved', stackId: '1', labelType: 'amount' , fill: 'secondary', fillActive: 'secondary'}, 
            ]
        },
        widgetLabels: {
            title: 'Inventory Levels',
        }
    },
    "sales.invoiced.monthly.widget": {
        widgetType: 'BarChartWidget',
        widgetData: {
            entity: 'stats',
            load: true,
            navigator: true,
            stats: 'invoicedMonthly',
            qString: 'state:final;from:2024-01-01;to:2024-12-31',
            labelFormat: 'amount',
            bars: [
                { dataKey: 'totalNetSalesAmount', name: 'Total Net Sales Amount', stackId: '1', fill: undefined, fillActive: undefined },
            ]
        },
        widgetLabels: {
            title: 'Total Monthly Sales',
            text: 'Here we will have a bar chart'
        }
    }

}