import styled from 'styled-components'
import useTranslation from '../../hooks/useTranslation'
import { UpArrow, DownArrow } from '../generic/SortArrow'
import TableRows from './TableRows'
import TableRows_m from './TableRows_m'

const TableContent = styled.div`
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    box-shadow: ${({ theme }) => theme.table.boxShadow};
    background: ${({ theme }) => theme.table.background};
`

const TableTitleWrapper = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 8px;
    margin-top: 4px;
    -webkit-box-align: center;
    align-items: center;
    background: ${({ theme }) => theme.area.bg};
    z-index: 100;
`

const TableFoot = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`

const TableHeaderWrapper = styled.div`
    position: sticky;
    top: 0px;
    z-index: 99;
    border-top-right-radius:1rem;
    border-top-left-radius:1rem;
    
    @media (max-width: 568px) {
        display: none;
    }
`

const HeaderRow = styled.header`
    border-top-right-radius: 1rem;
    background: ${({ theme }) => theme.table.header};
    border-top-left-radius: 1rem;
    display: flex;
    flex: 1 0 auto;
    min-width: 0px;
    height: 56px;
    position: relative;
    z-index: 100;
    border-bottom: 1px solid ${({ theme }) => theme.table.border};
    box-shadow: rgb(105 117 136 / 20%) 0px 1px 3px;
    -webkit-box-align: center;
    align-items: center;
    color: ${({ theme }) => theme.font.color};
    white-space: nowrap;
    padding: 0px 24px;
    @media (max-width: 568px) {
        display: none;
    }
`

const HeaderCell = styled.div`
    box-sizing: border-box;
    flex: 100 0 auto;
    min-width: 0px;
    width: ${(props) => (props.$len)} ;
    display: flex;
    margin-right: 24px;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    justify-content: flex-start;
    flex-shrink: 0 !important;
    -webkit-box-flex: 0 !important;
    flex-grow: 0 !important; 
    overflow-wrap: break-word;

    p {
        font-size: var(--font-XS);
        line-height: 24px;
        margin:0;
        display: block;
        color: ${({ theme }) => theme.font.color};
        font-family: "Euclid Circular B", sans-serif;
        font-weight: 600;
        text-align: inherit;
        user-select: auto;
        transition-property: color;
        transition-duration: 0.1s;
        transition-timing-function: ease-out;
        &:hover{
            color: ${({ theme }) => theme.table.hover}
        }
    }

    a{
        display: inline;
        color: currentcolor;
        font: inherit;
        cursor: pointer;
        text-decoration: none;
        transition-property: color;
        transition-duration: 0.1s;
        transition-timing-function: ease-out;
    }
`

const TableHeaderContent = styled.div`
    position: relative; /* Makes this the reference point for absolute positioning */
`

const HeaderBackground = styled.div`
    position: absolute;
    top: -0.5rem;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.area.bg};
    z-index: -100; /* Positions it behind HeaderRow */
    
`
export const TableDecorator = styled.span`
      font-size: var(--font-XXS);
      margin-left: 0.25rem;  
`

const TableHeader = ({ columns, order, sortList }) => {
    const { t } = useTranslation()
    return (
        <TableHeaderWrapper name='TableHeaderWrapper'>
            <TableHeaderContent>
                <HeaderBackground />
                <HeaderRow>
                    {
                        columns.map(
                            (col) => {
                                return (
                                    <HeaderCell $len={col.length} key={col.id} onClick={(e) => { sortList(col.id) }}>
                                        <p><a>{t(col.name)}</a></p>
                                        {order[0] === col.id && order[1] === 'asc' ? <UpArrow /> : <></>}
                                        {order[0] === col.id && order[1] === 'desc' ? <DownArrow /> : <></>}
                                    </HeaderCell>
                                )
                            }
                        )
                    }
                </HeaderRow>
            </TableHeaderContent>
        </TableHeaderWrapper>
    )
}

let Table = {}

Table.Content = TableContent
Table.TitleWrapper = TableTitleWrapper
Table.Foot = TableFoot
Table.Header = TableHeader
Table.Rows = TableRows
Table.RowsMobile = TableRows_m
Table.Decorator = TableDecorator

export default Table