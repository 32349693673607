import { useEffect, useState } from "react"
import listViewData from "../../model/components/listViewData" 
import ViewTitle from "./ViewTitle"
import ViewFilter from "./ViewFilter"
import { useParams } from "react-router-dom"

import MobileNavBar from "../mobileNav/MobileNavBar"
import useActiveElement from "../../hooks/useActiveElement"
import { getFromLS } from "../../utils/localStore/localStore"
import generateEndpoint from "../../utils/generateEndpoint"

//API Interface
import ViewTable from "./ViewTable"

const ListView = ({ type, options }) => {
  const { id } = useParams()

  //Retrieve the current active FilterView.
  let currentActiveFilter = getFromLS(`activeList`, type);
  if (!currentActiveFilter) currentActiveFilter = 0

  if (!listViewData[type]) return <h2>Nothing to display, please define the list view for : "{type}"</h2>
  const config = listViewData[type]


  //Initializes the table we are working on.
  const [table, setTable] = useState(config.tables[config.entity])
  const [endpoint, setEndpoint] = useState(generateEndpoint(config.endpoint, id))

  //This manages which Filter tab is selected and saves it to LocalStorage, so it remembers.
  const { activeElem, handleActiveElem } = useActiveElement(`activeList`, currentActiveFilter, type)

  if (!options) {
    options = {
      viewFilters: true,
    }
  }

  useEffect(() => {
    //If filter has its own entity it is used instead of the global one
    if (config?.filters && config.filters.list[activeElem]?.entity) {
      setTable(config.tables[config.filters.list[activeElem].entity])
      setEndpoint(config.filters.list[activeElem].entity)
    }
  }, [activeElem])



  return (
    <>
      <ViewTitle config={config} />
      {options.viewFilters && <ViewFilter filters={config.filters} filter={activeElem} setFilter={handleActiveElem} />}
      {endpoint && <ViewTable
        table={table}
        config={config}
        endpoint={endpoint}
        filter={activeElem}
        id={id}
      >
      </ViewTable>}
      {!config.hideNavigation && <MobileNavBar />}
    </>
  )
}

export default ListView
