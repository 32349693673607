import styled from 'styled-components';
import extractDataFromPath from '../../../../common/utils/object/extractDataFromPath'

//Import SubComponents
import Tabs from "../tabs/Tabs";
import { getTabsConfig } from './config/tabsConfig';

const Body = styled.div`
    flex: 1;
    overflow-y: auto;
    padding: 0;
`;

const TabList = ({
    entity,
    validated,
    useStore,
    section,
    lineId,
    path
}) => {

    // Get local state for constraints and parameters
    const [fullState] = useStore(s => s)
    section.entity = entity

    let data = null;
    if (section.meta?.calculate) {
        data = extractDataFromPath(fullState, section.meta.calculate);
    }
    const reservations = fullState.reservations;

    const tabsConfig = getTabsConfig({
        data,
        reservations,
        section,
        useStore,
    });

    return (
        <div>
            <Body>
                <Tabs config={tabsConfig} id='{type}' type={''} />
            </Body>
        </div>
    )
}

export default TabList