import { proxy } from "valtio";

export const ThemeContext = proxy({
    currentTheme: 'standard',
    currentColor: 'blue',
    secondaryColor: 'orange',
    currentMode: 'light',
    currentStyle: 'flat',
    currentNavMode: 'light'
})


