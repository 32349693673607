import useTranslation from "../hooks/useTranslation"
import useProfile from "../hooks/useProfile"
import useLocale from "../hooks/useLocale"
import { useSnapshot } from "valtio"

const useFormatting = () => {

    const { t } = useTranslation()
    const { pState } = useProfile()
    const profile = useSnapshot(pState)
    const { lf } = useLocale()

    const formatString = (value, displayType, item, col) => {
        let result = value

        if (displayType === 'date' || displayType === 'niceDate') {
            if (value === undefined) {
                value = new Date().toISOString()
            }
        }

        if (displayType === 'date') {
            const date = new Date(value)
            result = date.toLocaleDateString(profile.currentOrgLanguage)

        }

        if (displayType === 'niceDate') {
            //Formats in a nice humar readable Date like : 23. januar 2024
            const date = new Date(value)
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
            const formattedDate = new Intl.DateTimeFormat(profile.currentOrgLanguage, options).format(date);
            result = formattedDate
        }

        if (displayType === 'amount') {
            result = lf(value, 2, 2)
        }

        if (displayType === 'roundedAmount') {
            result = lf(Math.round(value), 0, 0)
        }

        if (displayType === 'quantity' && value === undefined) {
            result = '0'
        }

        if (displayType === 'quantity' && value !== undefined) {
            result = Number(value)
        }


        if (displayType === 'boolean') {
            if (item[col.id] === true) {
                result = t(col.values[0])
            } else {
                result = t(col.values[1])
            }
        }

        if (displayType === 'code') {
            result = value.toUpperCase()
        }

        if (displayType === 'code7') {
            result = value.toUpperCase().substring(0, 7)
        }

        if (displayType === 'label') {
            result = t(value)
        }

        return result

    }

    return {formatString}
}

export default useFormatting