import React from "react"
import CardView from "../../cardView/CardView" 
import { tabViewData } from "../../../model/components/tabViewData"
import Tabs from "../../tabs/Tabs"
import styled from "styled-components"
import { useStore } from "../Page"
import SubList from "../../subList/SubList"
import ListView from "../../listView/ListView"

const Wrapper = styled.div`
  max-width: ${ ({width}) => width };
`

const componentMap = {
  'SubList': SubList,
  'ListView': ListView
}

const TabView = ({ type, options, data }) => {
  const config = tabViewData[type]
  let condition, conditionValue

  if (config.condition) {
    [condition] = useStore((s) => s[config.condition.field])
    conditionValue = {
      result: config.condition.value === condition,
      tab: config.condition.tab
    }
  }

  return (
    <Wrapper width={config.width}>
      <CardView data={data} type={config.cardViewType} options={options} />
      {config.dynamic ? (
        <Tabs 
          config={config.tabs.map(tab => ({
            ...tab,
            content: React.createElement(componentMap[tab.content], {
              data,
              useStore,
              tab
            })
          }))} 
          id={type} 
          type={''} 
          conditionValue={conditionValue}
        />
      ) : (
        <Tabs 
          config={config.tabs} 
          id={type} 
          type={''} 
          conditionValue={conditionValue}
        />
      )}
    </Wrapper>
  )
}

export default TabView
