const colorPairs = {
    "blue": "orange",
    "blueDark": "orangeDark",
    "cyan": "red",
    "cyanDark": "redDark",
    "green": "pink",
    "greenDark": "pinkDark",
    "grass": "purple",
    "grassDark": "purpleDark",
    "orange": "blue",
    "orangeDark": "blueDark",
    "tomato": "teal",
    "tomatoDark": "tealDark",
    "red": "cyan",
    "redDark": "cyanDark",
    "crimson": "gold",
    "crimsonDark": "goldDark",
    "pink": "green",
    "pinkDark": "greenDark",
    "plum": "grass",
    "plumDark": "grassDark",
    "purple": "lime",
    "purpleDark": "limeDark",
    "violet": "yellow",
    "violetDark": "yellowDark",
    "indigo": "orange",
    "indigoDark": "orangeDark",
    "teal": "crimson",
    "tealDark": "crimsonDark",
    "brown": "cyan",
    "brownDark": "cyanDark",
    "gold": "purple",
    "goldDark": "purpleDark",
    "bronze": "purple",
    "bronzeDark": "purpleDark",
    "gray": "pink",
    "grayDark": "pinkDark",
    "yellow": "violet",
    "yellowDark": "violetDark",
    "lime": "purple",
    "limeDark": "purpleDark",
    "jade": "yellow",
    "jadeDark": "yellowDark"
  }
  
  
  export default colorPairs;