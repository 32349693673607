import { Icon_People, Icon_Boxes, Icon_Delete, Icon_Gear, Icon_Design } from "../../styles/icons"

export const displayViewData = {
    invoiceDisplayView: {
        titleField: 'number',
        path: 'invoice',
        entity: 'invoice',
        backOnSubmit: true,
        width: '756px',
        sections: [
            {
                meta: {
                    type: 'regular',
                    name: 'invoiceHeader',
                    width: '756px'
                },
                display: {
                    type: 'regular',
                    emptyField: 'number',
                    isEmptyDisabled: true,
                    isHoveredDisabled: true,
                    columns: [
                        {
                            size: 5,
                            grow: '50%',
                            fields: [
                                {
                                    prefix: 'invoice.form.number.title.prefix',
                                    size: 'title',
                                    type: 'field',
                                    value: 'number'
                                },
                                {
                                    size: 'regular',
                                    type: 'date',
                                    value: 'date'
                                }
                            ]
                        },
                        {
                            size: 5,
                            grow: '50%',
                            align: 'right',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'field',
                                    value: 'customerName',
                                    icon: Icon_People,
                                    variant: 'noMargin'
                                },
                                {
                                    size: 'regular',
                                    type: 'field',
                                    value: 'customerEmail',
                                    variant: 'noMargin'
                                },
                                {
                                    size: 'regular',
                                    type: 'merge',
                                    value: '${1} - ${2} ${3} - ${4}',
                                    mergeParams: ['customerAddress_street', 'customerAddress_postCode', 'customerAddress_city', 'customerCountryName'],
                                    variant: 'noMargin'
                                }
                            ]
                        }
                    ]
                }
            },
            {
                meta: {
                    type: 'lines',
                    linesArray: 'lines',
                    name: 'invoiceLines',
                    width: '756px',
                    nFields: { 'netSalePrice': true, 'itemQuantity': true }
                },
                display: {
                    type: 'lines',
                    linesArray: 'lines',
                    emptyField: 'itemName',
                    emptyMessage: 'invoice.form.product.missing.message',
                    isEmptyDisabled: true,
                    isHoveredDisabled: true,
                    columns: [
                        {
                            size: 7,
                            grow: '70%',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'merge',
                                    value: '${1} (x${2})',
                                    icon: Icon_Boxes,
                                    mergeParams: ['itemName', 'itemQuantity']
                                },
                                {
                                    size: 'regular',
                                    type: 'field',
                                    value: 'itemDescription'
                                }
                            ]
                        },
                        {
                            size: 3,
                            grow: '30%',
                            align: 'end',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'amount',
                                    value: 'netSalesAmount',
                                    variant: 'noMargin'
                                },
                                {
                                    size: 'regular',
                                    type: 'text',
                                    value: 'DKK',
                                    variant: 'noMargin'
                                }
                            ],
                        }
                    ]
                }
            },
            {
                meta: {
                    type: 'totals',
                    name: 'invoiceSummary',
                    width: '756px',
                    variant: 'transparent',
                    align: 'end'
                },
                display: {
                    type: 'totals',
                    fields: [
                        {
                            type: 'line',
                            size: 'regular',
                            label: 'Subtotal',
                            value: 'totalNetSalesAmount',
                            deco: 'currency'
                        },
                        {
                            type: 'array',
                            subEntity: 'vatTaxes',
                            size: 'regular',
                            label: 'VAT ${1}% of ${2}',
                            labelParams: [['vatTaxRate', 100], 'vatTaxAmount'],
                            value: 'vatTaxAmount',
                            deco: 'currency'
                        },
                        {
                            type: 'line',
                            size: 'large',
                            label: 'Total Amount',
                            value: 'totalGrossSalesAmount',
                            deco: 'currency'
                        }
                    ]
                }
            },
            {
                meta: {
                    type: 'regular',
                    name: 'invoiceTerms',
                    width: '756px',
                },
                display: {
                    emptyField: 'terms',
                    isEmptyDisabled: true,
                    isHoveredDisabled: true,
                    columns: [
                        {
                            size: 1,
                            grow: '100%',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'text',
                                    value: 'Terms',
                                },
                                {
                                    size: 'regular',
                                    type: 'field',
                                    value: 'terms'
                                }
                            ]
                        }
                    ]
                }
            },
        ],
        cardTitle: {
            title: 'all.form.button.more',
            buttons: [
                {
                    action: 'send',
                    confirm: true,
                    modalConfig: 'invoiceSendDialog',
                    variant: 'primary',
                    label: 'all.form.button.send'
                }
            ],
            menuItems: [
                {
                    label: 'all.menu.item.save',
                    action: 'save',
                    confirm: false,
                },
                {
                    dependency: { id: 'archived', value: false },
                    label: 'all.menu.item.archive',
                    action: 'archive',
                    confirm: false,
                },
                {
                    dependency: { id: 'archived', value: true },
                    label: 'all.menu.item.unarchive',
                    action: 'unarchive',
                    confirm: false,
                },
                {
                    label: 'all.menu.item.credit',
                    action: 'credit',
                    confirm: true,
                    modalConfig: 'invoiceConfirmCredit'
                }
            ],
            actions: {
                archive: { message: 'invoice.action.archive.message' },
                unarchive: { message: 'invoice.action.unarchive.message' }
            }
        },
        mobilePanel: {
            sections: [
                {
                    type: 'actionButton',
                    title: 'Show Preview',
                    entity: 'invoice',
                    modalGroups: [
                        {
                            type: 'pdf',
                        }
                    ]
                }
            ]
        },
    },
    creditNoteDisplayView: {
        titleField: 'number',
        path: 'creditNote',
        entity: 'creditNote',
        backOnSubmit: true,
        width: '756px',
        sections: [
            {
                meta: {
                    type: 'regular',
                    name: 'creditNoteViewPage',
                    width: '756px'
                },
                display: {
                    type: 'regular',
                    emptyField: 'number',
                    isEmptyDisabled: true,
                    isHoveredDisabled: true,
                    columns: [
                        {
                            size: 5,
                            grow: '50%',
                            fields: [
                                {
                                    prefix: 'document.form.number.title.prefix',
                                    size: 'title',
                                    type: 'field',
                                    value: 'number'
                                },
                                {
                                    size: 'regular',
                                    type: 'date',
                                    value: 'date'
                                }
                            ]
                        },
                        {
                            size: 5,
                            grow: '50%',
                            align: 'right',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'field',
                                    value: 'customerName',
                                    icon: Icon_People,
                                    variant: 'noMargin'
                                },
                                {
                                    size: 'regular',
                                    type: 'field',
                                    value: 'customerEmail',
                                    variant: 'noMargin'
                                },
                                {
                                    size: 'regular',
                                    type: 'merge',
                                    value: '${1} - ${2} ${3} - ${4}',
                                    mergeParams: ['customerAddress_street', 'customerAddress_postCode', 'customerAddress_city', 'customerCountryName'],
                                    variant: 'noMargin'
                                }
                            ]
                        }
                    ]
                }
            },
            {
                meta: {
                    type: 'lines',
                    linesArray: 'lines',
                    name: 'invoiceLines',
                    width: '756px',
                    nFields: { 'netSalePrice': true, 'itemQuantity': true }
                },
                display: {
                    type: 'lines',
                    linesArray: 'lines',
                    emptyField: 'itemName',
                    emptyMessage: 'invoice.form.product.missing.message',
                    isEmptyDisabled: true,
                    isHoveredDisabled: true,
                    columns: [
                        {
                            size: 7,
                            grow: '70%',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'merge',
                                    value: '${1} (x${2})',
                                    icon: Icon_Boxes,
                                    mergeParams: ['itemName', 'itemQuantity']
                                },
                                {
                                    size: 'regular',
                                    type: 'field',
                                    value: 'itemDescription'
                                }
                            ]
                        },
                        {
                            size: 3,
                            grow: '30%',
                            align: 'end',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'amount',
                                    value: 'netSalesAmount',
                                    variant: 'noMargin'
                                },
                                {
                                    size: 'regular',
                                    type: 'text',
                                    value: 'DKK',
                                    variant: 'noMargin'
                                }
                            ],
                        }
                    ]
                }
            },
            {
                meta: {
                    type: 'totals',
                    name: 'invoiceSummary',
                    width: '756px',
                    variant: 'transparent',
                    align: 'end'
                },
                display: {
                    type: 'totals',
                    fields: [
                        {
                            type: 'line',
                            size: 'regular',
                            label: 'Subtotal',
                            value: 'totalNetSalesAmount',
                            deco: 'currency'
                        },
                        {
                            type: 'array',
                            subEntity: 'vatTaxes',
                            size: 'regular',
                            label: 'VAT ${1}% of ${2}',
                            labelParams: [['vatTaxRate', 100], 'vatTaxAmount'],
                            value: 'vatTaxAmount',
                            deco: 'currency'
                        },
                        {
                            type: 'line',
                            size: 'large',
                            label: 'Total Amount',
                            value: 'totalGrossSalesAmount',
                            deco: 'currency'
                        }
                    ]
                }
            },
            {
                meta: {
                    type: 'regular',
                    name: 'invoiceTerms',
                    width: '756px',
                },
                display: {
                    emptyField: 'terms',
                    isEmptyDisabled: true,
                    isHoveredDisabled: true,
                    columns: [
                        {
                            size: 1,
                            grow: '100%',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'text',
                                    value: 'Terms',
                                },
                                {
                                    size: 'regular',
                                    type: 'field',
                                    value: 'terms'
                                }
                            ]
                        }
                    ]
                }
            },
        ],
        cardTitle: {
            title: 'all.form.button.more',
            buttons: [
                {
                    action: 'send',
                    confirm: true,
                    modalConfig: 'invoiceSendDialog',
                    variant: 'primary',
                    label: 'all.form.button.send'
                }
            ],
            menuItems: [
                {
                    label: 'all.menu.item.save',
                    action: 'save',
                    confirm: false,
                },
                {
                    dependency: { id: 'archived', value: false },
                    label: 'all.menu.item.archive',
                    action: 'archive',
                    confirm: false,
                },
                {
                    dependency: { id: 'archived', value: true },
                    label: 'all.menu.item.unarchive',
                    action: 'unarchive',
                    confirm: false,
                },
                {
                    label: 'Match',
                    action: 'match',
                    confirm: false,
                }

            ],
            actions: {
                archive: { message: 'invoice.action.archive.message' },
                unarchive: { message: 'invoice.action.unarchive.message' },
                match: { message: 'invoice.action.match.message' }
            }
        },
        mobilePanel: {
            sections: [
                {
                    type: 'actionButton',
                    title: 'Show Preview',
                    entity: 'invoice',
                    modalGroups: [
                        {
                            type: 'pdf',
                        }
                    ]
                }
            ]
        },
    },
    stockAdjustmentDisplayView: {
        titleField: 'number',
        path: 'stockAdjustment',
        entity: 'stockAdjustment',
        backOnSubmit: true,
        width: '756px',
        sections: [
            {
                meta: {
                    type: 'regular',
                    name: 'stockAdjustmentDiplayHeader',
                    width: '756px'
                },
                display: {
                    type: 'regular',
                    emptyField: 'number',
                    isEmptyDisabled: true,
                    isHoveredDisabled: true,
                    columns: [
                        {
                            size: 5,
                            grow: '50%',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'field',
                                    value: 'number'
                                },
                                {
                                    size: 'regular',
                                    type: 'date',
                                    value: 'date'
                                }
                            ]
                        },
                        {
                            size: 5,
                            grow: '50%',
                            align: 'right',
                            fields: [
                                {
                                    size: 'regular',
                                    type: 'field',
                                    value: 'note',
                                }
                            ]
                        }
                    ]
                }
            },
            {
                meta: {
                    type: 'lines',
                    linesArray: 'lines',
                    name: 'invoiceLines',
                    width: '756px',
                    nFields: { 'netSalePrice': true, 'itemQuantity': true }
                },
                display: {
                    type: 'lines',
                    linesArray: 'lines',
                    emptyField: 'itemName',
                    emptyMessage: 'invoice.form.product.missing.message',
                    isEmptyDisabled: true,
                    isHoveredDisabled: true,
                    columns: [
                        {
                            size: 7,
                            grow: '70%',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'merge',
                                    value: '${1} (x${2})',
                                    icon: Icon_Boxes,
                                    mergeParams: ['itemName', 'quantity']
                                },
                                {
                                    size: 'regular',
                                    type: 'field',
                                    value: 'itemDescription'
                                }
                            ]
                        },
                        {
                            size: 3,
                            grow: '30%',
                            align: 'end',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'amount',
                                    value: 'quantity',
                                    variant: 'noMargin'
                                },
                                {
                                    size: 'regular',
                                    type: 'field',
                                    value: 'unitName',
                                    variant: 'noMargin'
                                }
                            ],
                        }
                    ]
                }
            },
        ],
        cardTitle: {
            title: 'all.form.button.more',
            buttons: [
                {
                    action: 'close',
                    confirm: false,
                    modalConfig: 'confirmClose',
                    variant: 'primary',
                    label: 'all.form.button.close'
                }
            ]
        }
    }
}