import ListView from "../../../components/listView/ListView";
import Page from "../../../components/pageView/Page";
import WidgetPage from "../../../components/widgetPage/WidgetPage";

const routesBank = [
  {
    path: "/bank",
    element: WidgetPage,
    props: {
      type: "bank",
      draggable: false,
      resizable: false,
    },
  }
];

export default routesBank;
