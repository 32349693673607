import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList, ResponsiveContainer } from 'recharts';

import useTheme from '../../../../hooks/useTheme';
import useFormatting from '../../../../hooks/useFormatting';

const BarChartWidget = ({ widgetData, chartData }) => {
    const { theme } = useTheme()

    const primaryColor = theme.chart.fillColor
    const activeColor = theme.chart.activeFillColor
    const secondaryColor = theme.secondary.main
    const activeSecondaryColor = theme.secondary.active




    //const fillColor = theme.chart.fillColor
    const fillColor = theme.secondary.main
    //const activeFillColor = theme.chart.activeFillColor
    const activeFillColor = theme.secondary.active

    const { formatString } = useFormatting()
    const { bars } = widgetData
    const labelFormat = widgetData?.labelFormat || 'percentage'

    const formatters = {
        amount: (value) => formatString(value, 'roundedAmount'),
        percentage: (value) => `${(value / 1000).toFixed(1)}%`,
        // Add more formats as needed
    };

    const formatLabel = (value) => {
        const formatter = formatters[labelFormat] || ((v) => `${v}`); // Default to no formatting
        return formatter(value) === '0' ? '' : formatter(value);
    };

    return (
        <ResponsiveContainer width="100%" height="100%" padding="10px">
            <BarChart
                width={500}
                height={300}
                data={chartData}
                margin={{
                    top: 15,
                    right: 10,
                    left: 10,
                    bottom: 35,
                }}
            >
                <XAxis dataKey="key"
                    tick={{
                        fontSize: '0.85rem',
                        fill: theme.font.color,
                        stroke: theme.font.color,
                        strokeWidth: 0
                    }}
                />
                <YAxis
                    tick={{
                        fontSize: '0.85rem',
                        fill: theme.font.color,
                        stroke: theme.font.color,
                        strokeWidth: 0
                    }}
                />
                <Tooltip contentStyle={{ fontSize: '0.85rem', backgroundColor: theme.card.selected, padding: '10px', borderRadius: '1rem' }} cursor={{ fill: theme.card.active, opacity: 0.4 }} />
                <Legend wrapperStyle={{ fontSize: '0.85rem' }} />

                {
                    bars.map((bar, index) => {
                        const fill = bar.fill || primaryColor
                        const activeFill = bar.fillActive || activeColor
                        return <Bar name={bar.name} key={index} dataKey={bar.dataKey}
                            fill={fill === 'primary' ? primaryColor : fill === 'secondary' ? secondaryColor : fill === undefined ? fillColor : fill}
                            radius={[5, 5, 0, 0]}
                            activeBar={<Rectangle
                                fill={fill === 'primary' ? activeColor : fill === 'secondary' ? activeSecondaryColor : fill === undefined ? activeFillColor : activeFill}
                                radius={[5, 5, 0, 0]} />} >
                            <LabelList dataKey={bar.dataKey} position="top"
                                style={{ fontSize: '0.85rem' }} formatter={formatLabel} /> </Bar>
                    })
                }
            </BarChart>
        </ResponsiveContainer>
    )
}

export default BarChartWidget