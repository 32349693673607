import { ThemeContext } from "../context/themeContext";
import { useSnapshot } from 'valtio';
import compileTheme from '../styles/themes';

const useTheme = () => {
  const { currentColor, secondaryColor, currentMode, currentStyle, currentNavMode } = useSnapshot(ThemeContext);
  
  // Compile theme with current values
  const theme = compileTheme(currentMode, currentColor, secondaryColor,currentStyle, currentNavMode);

  return { 
    ThemeContext,
    theme,
    // Also expose individual theme values for convenience
    currentColor,
    secondaryColor,
    currentMode, 
    currentStyle,
    currentNavMode
  };
}

export default useTheme;
