import { axiosPrivate } from "../api/axios"
import { useEffect } from 'react'
import useRefreshToken from "./useRefreshToken"
import useAuth from "./useAuth"
import { useNavigate } from "react-router-dom";

const useAxiosPrivate = () => {
    const refresh = useRefreshToken()
    const { Auth } = useAuth()
    const navigate = useNavigate()


    useEffect(
        () => {

            const requestIntercept = axiosPrivate.interceptors.request.use(
                config => {
                    if (!config.headers['Authorization']) {
                        config.headers['Authorization'] = `Bearer ${Auth?.accessToken}`
                    }
                    return config
                }, (error) => Promise.reject(error)
            )

            const responseIntercept = axiosPrivate.interceptors.response.use(
                response => response,
                async (error) => {
                    const prevRequest = error?.config;
                    
                    // Handle offline case first
                    if (!navigator.onLine) {
                        return Promise.reject({
                            ...error,
                            response: { data: { message: 'No internet connection' } }
                        });
                    }

                    // Handle case where there's no response (e.g., network error)
                    if (!error.response) {
                        return Promise.reject({
                            ...error,
                            response: { data: { message: 'Network error' } }
                        });
                    }

                    // Check if refresh token fails
                    if (error.response?.config?.url === '/token/refresh') {
                        console.log('Token Refresh Failed');
                        navigate('/loggedout');
                        return Promise.reject(error);
                    }

                    // Handle 403 with token refresh
                    if (error.response?.status === 403 && !prevRequest?.sent) {
                        try {
                            prevRequest.sent = true;
                            const newAccessToken = await refresh();
                            prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                            return axiosPrivate(prevRequest);
                        } catch (refreshError) {
                            // If refresh fails, navigate to logged out
                            navigate('/loggedout');
                            return Promise.reject(refreshError);
                        }
                    }

                    return Promise.reject(error);
                }
            );

            return () => {
                axiosPrivate.interceptors.request.eject(requestIntercept)
                axiosPrivate.interceptors.response.eject(responseIntercept)
            }
        }, [Auth, refresh]
    )

    return axiosPrivate;
}

export default useAxiosPrivate;
