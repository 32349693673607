import styled, { css } from 'styled-components'
import { getFromLS } from '../../utils/localStore/localStore'
import useActiveElement from '../../hooks/useActiveElement'
import useScreenSize from '../../hooks/useScreenSize'
import useTranslation from '../../hooks/useTranslation'

const IconWrap = styled.div`
  display: inline-block;
//  transform: translateY(-2px);
  font-size: 1.5rem;
  margin-right: 0.5rem;

  @media (max-width: 500px) {
    font-size: 1.5rem;
  }
`

const LabelWrap = styled.span`
  @media (max-width: 500px) {
    display: none; /* Hide by default on small screens */
    font-size: 1rem;

    &.active {
      display: inline; /* Show only when active */
      opacity: 1;
      color: inherit; /* Inherit color from parent TabButton */
    }
  }
`;



const TabList = styled.ul`
    
    --tabs-border-width: 5px;
    --tabs-border-radius: 0.375rem;
    --nav-link-padding-x: 1rem;
    --nav-link-padding-y: 0.5rem;
    --nav-link-font-weight: 500 ;
    margin-bottom: 1rem!important;
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    border-bottom: var(--tabs-border-width) solid ${({ theme }) => theme.tab.borderColor};

  &.rounded {
    border-bottom: var(--tabs-border-width) solid ${({ theme }) => theme.tab.activeBgColor};
  }
`

const TabListItem = styled.li`
  // Add your button styles here
`

const TabButton = styled.button`
  // Add your button styles here
    position: relative;
    margin-bottom: calc(var(--tabs-border-width) * -1);
    background: 0 0;
    border: var(--tabs-border-width) solid transparent;
    border-top-left-radius: var(--tabs-border-radius);
    border-top-right-radius: var(--tabs-border-radius);
    display: block;
    padding: var(--nav-link-padding-y) var(--nav-link-padding-x);
    font-size: var(--nav-link-font-size);
    font-weight: var(--nav-link-font-weight);
    text-decoration: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
    color: ${({ theme }) => theme.font.color};
    border-left: 0px;
    border-right: 0px;

&.active{
  color:${({ theme }) => theme.tab.navLinkColor};
  border-bottom: var(--tabs-border-width) solid ${({ theme }) => theme.tab.navLinkColor};

}

&.rounded{
  color:${({ theme }) => theme.tab.navLinkColor};
}

&.active.rounded{
  background-color: ${({ theme }) => theme.tab.activeBgColor};
  border-color: ${({ theme }) => theme.tab.activeBorderColor};
  color: ${({ theme }) => theme.tab.activeColor};
  border-bottom: 1px solid ${({ theme }) => theme.tab.borderColor};;

}

@media (hover: hover) {
  /* Only apply hover styles on devices that support hover */
  &.rounded:hover {
    isolation: isolate;
    border-color: ${({ theme }) => theme.tab.hoverBorder};
  }

  &.rounded:not(.active):hover {
    color: ${({ theme }) => theme.tab.hoverColor};
  }

  &:hover {
    border-bottom: var(--tabs-border-width) solid ${({ theme }) => theme.tab.hoverColor};
    color: ${({ theme }) => theme.tab.hoverColor};
  }
}

${({ disabled }) =>
    disabled &&
    css`
        opacity: 0.5; // Example: Reduce opacity for disabled button
        cursor: not-allowed; // Example: Change cursor style for disabled button
        color: ${({ theme }) => theme.tab.disabledColor};
        pointer-events: none;
    
    `}
`

const TabContentWrapper = styled.div`
  // Add your tab content styles here
`

const TabContent = styled.div`
  // Add your tab content styles here
  transition: opacity .15s linear;
  display: none;

  &.active{
 display: block;
}

`


const Tabs = ({ config, id, type, conditionValue }) => {
  // Try to retrieve the current Tab
  let currentActiveElement = getFromLS(`activeTab`, id);
  if (!currentActiveElement) currentActiveElement = config[0].key
  const { t } = useTranslation()

  const { isMobile } = useScreenSize()

  //Set the current tab to be the active element
  const { activeElem, handleActiveElem } = useActiveElement(`activeTab`, currentActiveElement, id)

  return (
    <>
      <TabList className={`${type}`} role="tablist">
        {config.map((tab) => (
          (!conditionValue || (tab.key !== conditionValue.tab) || (conditionValue.result && tab.key === conditionValue.tab))
          && (
            <TabListItem
              key={tab.key}
              role="presentation"
            >
              <TabButton
                type="button"
                id={`tab-${tab.key}`}
                role="tab"
                data-rr-ui-event-key={tab.key}
                aria-controls={`tabpane-${tab.key}`}
                aria-selected={activeElem === tab.key}
                className={`${activeElem === tab.key ? `active ${type}` : `${type}`}`}
                onClick={() => handleActiveElem(tab.key)}
                disabled={tab.disabled}
              >
                <IconWrap
                  className={`${activeElem === tab.key ? `active ${type}` : `${type}`}`}
                >
                  {tab.icon}
                </IconWrap>
                  <LabelWrap className={`${activeElem === tab.key ? `active ${type}` : `${type}`}`}>{t(tab.label)}</LabelWrap>
              </TabButton>
            </TabListItem>
          )
        ))}
      </TabList>

      <TabContentWrapper className="tab-content">
        {config.map((tab) => (
          (!conditionValue || (tab.key !== conditionValue.tab) || (conditionValue.result && tab.key === conditionValue.tab))
          && <TabContent
            key={tab.key}
            role="tabpanel"
            id={`tabpane-${tab.key}`}
            aria-labelledby={`tab-${tab.key}`}
            className={`${activeElem === tab.key ? 'active show' : ''
              }`}
          >
            {tab.content}
          </TabContent>
        ))}
      </TabContentWrapper>
    </>
  );
};

export default Tabs;
