import './App.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
// @ts-ignore
import { createElement as jsx, useEffect } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { useState } from 'react'

// @ts-ignore
import {getFromLS} from './utils/localStore/localStore.js'
// @ts-ignore
import useTranslation from './hooks/useTranslation'

import { useSnapshot } from 'valtio'

//Setting up Themes
import { ThemeProvider } from 'styled-components'
// @ts-ignore
import useTheme from './hooks/useTheme'
// @ts-ignore
import compileTheme from './styles/themes'

/* Importing Components*/
// @ts-ignore
import Loader from './components/generic/Loader'
// @ts-ignore
import entryRoutes from './model/routes/entryRoutes'
// @ts-ignore
import viewRoutes from './model/routes/viewRoutes'
// @ts-ignore
import Welcome from './pages/Welcome'
// @ts-ignore
import LoggedOut from './pages/LoggedOut'
// @ts-ignore
import Missing from './pages/Missing'
// @ts-ignore
import Layout from './pages/Layout'
// @ts-ignore
import AppBoard from './pages/apps/home/Index'
// @ts-ignore
import Hello from './pages/apps/hello/HelloWorld'
// @ts-ignore
import CreateOrg from './pages/CreateOrg'

/* Importing Auth Verifier */
// @ts-ignore
import RequireAuth from './pages/entry/RequireAuth'

//Setup the react query client
const queryClient = new QueryClient()

const dEntryRoutes =
  entryRoutes.map(
    // @ts-ignore
    (route, i) => {
      return (
        <Route path={route.path} key={i} element={jsx(route.element, route.props)} />
      )
    })


const dViews =
  viewRoutes.map(
    // @ts-ignore
    (route, i) => {
      return (
    // @ts-ignore
        <Route path={route.path} key={i} element={jsx(route.element, route.props)} />
      )
    }
  )

function App() {
//  let localMode = import.meta.env.VITE_LOCAL_MODE || false
//  console.log(`Local Mode is: ${localMode} `)

  const { theme, ThemeContext } = useTheme()
  const { tState, loadTrans } = useTranslation()
  const snap = useSnapshot(tState)

  //Initialize the Translation State
  const [isLoading, setIsLoading] = useState(true)


  const loadTranslations = async () => {
    await loadTrans()
    console.log('App.js Loaded Translations')
    setIsLoading(false)
    console.log(`Outlet-Key: ${location.pathname}/${snap.currentLang}`)
  }

  useEffect(
    () => {
      //Reads the Language from Local Storage, before loading the App
      const loadedLang = localStorage.getItem('language')
      if (loadedLang) {
        tState.currentLang = loadedLang   
      }
      //Reads the Theme from Local Storage, before loading the App
      console.log('App.js loading the Theme')
      const loadedTheme = getFromLS('Theme')
      if (loadedTheme) {
        console.log('LOADED THEME:',loadedTheme)
        if (loadedTheme?.themeMode ) ThemeContext.currentMode = loadedTheme.themeMode
        if (loadedTheme?.themeColor ) ThemeContext.currentColor = loadedTheme.themeColor
        if (loadedTheme?.themeStyle ) ThemeContext.currentStyle = loadedTheme.themeStyle
        if (loadedTheme?.themeNavMode ) ThemeContext.currentNavMode = loadedTheme.themeNavMode
      }
    }, []
  )

  // Reload the translations when language changes.
  useEffect(() => {
    console.log(('App.js Load Translation on Change of Language'))
    loadTranslations()
  }, [snap.currentLang])

  if (isLoading) {
    return (<Loader height='50vh' size='2rem'/>)
  } else {
    return (
      <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <Router>
              <Routes>
                <Route path="/" element={<Welcome />} />
                <Route path="/loggedout" element={<LoggedOut />} />
                {dEntryRoutes}
                <Route element={<RequireAuth />} >
                  <Route element={<Layout />} >
                    {dViews}
                    <Route path="/home/settings" element={<AppBoard />} />
                    <Route path="/hello" element={<Hello />} />
                    <Route path="/org" element={<CreateOrg />} />
                    {/* Catch all */}
                    <Route path="/*" element={<Missing />} />
                  </Route>
                </Route>
              </Routes>
            </Router>
          </ThemeProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    )
  }
}

export default App
