import { 
    Icon_Apps, Icon_Back, Icon_Boxes, Icon_Cart,Icon_Gear,Icon_Help,Icon_Home, Icon_Message, Icon_Org, Icon_Paper,Icon_People, Icon_Invoice, 
    Icon_Contacts,
    Icon_StockMovement,
    Icon_StockAdjustment
} from '../../styles/icons'



import * as AiIcons from 'react-icons/ai'
import * as IoIcons from 'react-icons/io'
import * as MdIcons from 'react-icons/md'
import * as BsIcons from 'react-icons/bs'
import * as GoIcons from 'react-icons/go'

export const mobileNavBarData = {
    home: [
        {
            title: 'Apps',
            icon: <MdIcons.MdOutlineApps />,
            path: '/home/apps'
        },
        {
            title: 'home.menu02',
            icon: <BsIcons.BsBuilding />,
            path: '/home/organization'
        },
        {
            title: 'home.menu03',
            icon: Icon_People,
            path: '/home/user'
        },
        {
            title: 'home.menu04',
            icon: <GoIcons.GoGear />,
            path: '/home/settings'
        }
    ],
    sales: [
        {
            title: 'sales.menu.home',
            path: '/sales',
            icon: Icon_Home
        },
        {
            title: 'sales.menu.invoices',
            path: '/sales/invoice',
            icon: Icon_Invoice,
        },
        {
            title: 'sales.menu.contacts',
            path: '/sales/contact',
            icon: Icon_People,
        },
        {
            title: 'sales.menu.products',
            path: '/sales/product',
            icon: Icon_Boxes,
        }
    ],
    inventory: [
        {
            title: 'all.menu.home',
            path: '/inventory',
            icon: Icon_Home
        },
        {
            title: 'inventory.menu.products',
            path: '/inventory/product',
            icon: Icon_Boxes,
        },
        {
            title: 'inventory.menu.adjustments',
            path: '/inventory/adjustment',
            icon: Icon_StockAdjustment,
        },
        {
            title: 'inventory.menu.movements',
            path: '/inventory/movement',
            icon: Icon_StockMovement,
        }
    ],
    purchases: [
        {
            title: 'all.menu.home',
            path: '/purchases',
            icon: Icon_Home
        },
        {
            title: 'purchases.menu.contacts',
            path: '/purchases/contact',
            icon: Icon_Contacts,
        }
    ],
    crm: [
        {
            title: 'crm.menu01',
            path: '/crm',
            icon: <AiIcons.AiFillHome />
        },
        {
            title: 'crm.menu02',
            path: '/crm/customer',
            icon: <IoIcons.IoMdPeople />,
        },
        {
            title: 'crm.menu03',
            path: '/crm/contact',
            icon: <IoIcons.IoMdPeople />,
        },
        {
            title: 'crm.menu04',
            path: '/crm/product',
            icon: <BsIcons.BsBoxes />,
        }
    ],
    service: [
        {
            title: 'service.menu01',
            path: '/service',
            icon: <AiIcons.AiFillHome />
        },
        {
            title: 'service.menu02',
            path: '/crm/customer',
            icon: <IoIcons.IoMdPeople />,
        },
        {
            title: 'service.menu04',
            path: '/crm/product',
            icon: <MdIcons.MdLocalGroceryStore />,
        },
        {
            title: 'service.menu00',
            path: '/home',
            icon: <IoIcons.IoMdArrowBack />
        },
    ],
    bank: [
        {
            title: 'bank.menu01',
            path: '/bank',
            icon: <AiIcons.AiFillHome />
        },
        {
            title: 'bank.menu02',
            path: '/bank',
            icon: <IoIcons.IoMdPeople />,
        },
        {
            title: 'bank.menu04',
            path: '/bank',
            icon: <MdIcons.MdLocalGroceryStore />,
        },
        {
            title: 'bank.menu00',
            path: '/home',
            icon: <IoIcons.IoMdArrowBack />
        },
    ]
}