import { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import colors from '../../styles/colors';
import useTheme from '../../hooks/useTheme';

const SelectWrapper = styled.div`
  position: relative;
  width: ${({width}) => width || '100%'};
`;

const SelectButton = styled.div`
  background: ${({theme}) => theme.dropdown.background};
  color: ${({theme}) => theme.font.color};
  border-radius: 0.375rem;
  border: 1px solid #ced4da;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: var(--font-S);
  font-weight: 400;
  line-height: 1.25;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

  &:hover {
    border-color: #b3b3b3;
  }
`;

const ColorBox = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 0.25rem;
  background-color: ${({color}) => color};
  border: 1px solid ${({theme}) => theme.isDark ? '#ffffff20' : '#00000020'};
`;

const DropdownList = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: 0.25rem;
  background: ${({theme}) => theme.dropdown.background};
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  max-height: 300px;
  overflow-y: auto;
  z-index: 1000;
`;

const DropdownItem = styled.div`
  padding: 0.375rem 0.75rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: background-color .15s ease-in-out;
  
  &:hover {
    background-color: ${({theme}) => theme.isDark ? '#ffffff10' : '#00000010'};
  }

  ${({selected, theme}) => selected && `
    background-color: ${theme.isDark ? '#ffffff20' : '#00000010'};
  `}
`;

const ColorName = styled.span`
  color: ${({theme}) => theme.font.color};
  font-size: var(--font-S);
  text-transform: capitalize;
`;

const ColorSelector = ({ target, onColorSelect, width }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { isDark, currentColor, secondaryColor } = useTheme();
  
  const targetColor = target === 'color' ? currentColor : secondaryColor

  // Initialize with theme color
  const [selectedName, setSelectedName] = useState( targetColor);  
  const [selectedColor, setSelectedColor] = useState(() => {
    const colorKey = targetColor; 
    return colors[colorKey][`${targetColor}10`];
  });
  const dropdownRef = useRef(null);

  const baseColors = Object.keys(colors).filter(color => !color.endsWith('Dark'));

  const handleColorSelect = (colorName) => {
    const colorKey = isDark ? `${colorName}Dark` : colorName;
    const selectedColorValue = colors[colorKey][`${colorName}10`];
    setSelectedColor(selectedColorValue);
    setSelectedName(colorName);
    let e = { target: { value: colorKey } }
    onColorSelect(e);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);


  useEffect(() => {
    if (target === 'color') {
      setSelectedName(currentColor)
    } else {
      setSelectedName(secondaryColor)
    }
  }, [currentColor, secondaryColor])

  return (
    <SelectWrapper ref={dropdownRef} width={width}>
      <SelectButton onClick={() => setIsOpen(!isOpen)}>
        {selectedColor && <ColorBox color={colors[selectedName][`${selectedName}10`]} />}
        <ColorName>{selectedName || 'Select a color'}</ColorName>
      </SelectButton>

      {isOpen && (
        <DropdownList>
          {baseColors.map((colorName) => {
            const colorKey = isDark ? `${colorName}Dark` : colorName;
            const colorValue = colors[colorKey][`${colorName}10`];
            return (
              <DropdownItem
                key={colorName}
                onClick={() => handleColorSelect(colorName)}
                selected={selectedColor === colorValue}
              >
                <ColorBox color={colorValue} />
                <ColorName>{colorName}</ColorName>
              </DropdownItem>
            );
          })}
        </DropdownList>
      )}
    </SelectWrapper>
  );
};

export default ColorSelector;
