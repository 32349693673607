import { useState } from "react";

import { Icon_Arrow_Left, Icon_Arrow_Right } from "../../../styles/icons";
import styled from "styled-components";


import useApi from "../../../hooks/useApi";
import { useQuery } from "react-query";

import Loader from "../../generic/Loader";
import BarChartWidget from "./charts/BarChartWidget";
import IconButton from "../../generic/IconButton";

import addMissingMonths from "./utils/addMissingMonths";

const Title = styled.div`
    display: flex;
    justify-content: space-between;
    margin-left: 1rem;
`
const Navigator = styled.div`
    display: flex;
    margin-right: 1rem;
`

const defaultData = [
    {
        name: 'Main WH',
        Quantity: 4000,
        Reserved: 2400,
    },
    {
        name: 'South WH',
        Quantity: 3000,
        Reserved: 1398,
    },
    {
        name: 'North WH',
        Quantity: 2000,
        Reserved: 9800,
    },
    {
        name: 'Small WH',
        Quantity: 2780,
        Reserved: 3908,
    }
];

const ChartWidget = ({ widgetData, type = '', labels = '' }) => {
    const now = new Date()
    const year = now.getFullYear()
    const month = now.getMonth() + 1

    const numberOfMonths = 12; // Include 12 months in the result

    const [date, setDate] = useState({ year: year, month: month });
    const { getMe } = useApi()

    const increaseMonth = () => {
        setDate((prevDate) => {
            const newMonth = prevDate.month + 1;
            return {
                year: newMonth > 12 ? prevDate.year + 1 : prevDate.year,
                month: newMonth > 12 ? 1 : newMonth,
            };
        });
    };

    const decreaseMonth = () => {
        setDate((prevDate) => {
            const newMonth = prevDate.month - 1;
            return {
                year: newMonth < 1 ? prevDate.year - 1 : prevDate.year,
                month: newMonth < 1 ? 12 : newMonth,
            };
        });
    };

    // Calculate the date range for the API call
    const getDateRange = () => {
        let toDate = new Date(date.year, date.month - 1);
        let fromDate = new Date(toDate.getFullYear(), toDate.getMonth() - numberOfMonths + 1);
        return {
            from: `${fromDate.getFullYear()}-${(fromDate.getMonth() + 1).toString().padStart(2, '0')}-01`,
            to: `${toDate.getFullYear()}-${(toDate.getMonth() + 1).toString().padStart(2, '0')}-31`
        };
    };

    const dateRange = getDateRange();

    const loadData = async () => {
        if (widgetData?.load === false)  return 
        const response = await getMe(`stats/?stat=invoicedMonthly&from=${dateRange.from}&to=${dateRange.to}`)
        return response
    }

    const { data: statsData, isLoading, isError, error } = useQuery(['stats', date], loadData, { keepPreviousData: true })

    if (isLoading) return <Loader />

    if (isError) {
        console.error('Error fetching stats:', error)
        return <div>Error loading chart data: {error.message}</div>
    }

    let chartData
    if (widgetData?.load) {
        const rawData = statsData?.data || defaultData
        chartData = addMissingMonths(rawData, "totalNetSalesAmount", date.year, date.month, numberOfMonths);
    } else {
        chartData = defaultData
    }
    return (
        <>
            <Title>
                <h3>{labels.title}</h3>
                {widgetData.navigator && <Navigator>
                    <IconButton icon={Icon_Arrow_Left} $variant='primary' clickAction={decreaseMonth} />
                    <IconButton icon={Icon_Arrow_Right} $variant='primary' clickAction={increaseMonth} />
                </Navigator>}
            </Title>
            <BarChartWidget widgetData={widgetData} chartData={chartData} />

        </>
    )
}

export default ChartWidget
